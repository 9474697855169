<template>
  <HeaderDialog
    :show="openPostponeRoamingDialog"
    :title="$t('activateMicrosoft.dialog.title')"
    width="600"
    readOnly
    :loading="loading"
    @close="
      () => {
        togglePostponeRoamingDialog(false);
        $emit('close');
      }
    "
  >
    <template v-slot:body>
      <div class="mt-4 mx-3">
        <p>{{ $t("activateMicrosoft.dialog.description") }}</p>
        <ActiveDisabledSwitch
          color="accent"
          :value="postponeRoaming"
          @change="toggleActivation()"
          :disable_description="$t('activateMicrosoft.dialog.disable')"
          :activate_description="$t('activateMicrosoft.dialog.activate')"
          :disabled="needSync || loading"
        />
        <Alert dense color="warning" class="mb-0">
          {{ $t("activateMicrosoft.dialog.warning") }}
        </Alert>
      </div>
    </template>
  </HeaderDialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import ActiveDisabledSwitch from "@/components/base/ActiveDisabledSwitch.vue";

export default {
  name: "PostponeRoamingDialog",

  components: {
    ActiveDisabledSwitch,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters([
      "isCompanyMicrosoft",
      "postponeRoaming",
      "token",
      "isDashTester",
      "openPostponeRoamingDialog",
    ]),
    needSync() {
      if (!this.isCompanyMicrosoft) {
        return false;
      }
      if (this.postponeRoaming === null || this.postponeRoaming === undefined) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapMutations([
      "setCompanyPostponeRoaming",
      "togglePostponeRoamingDialog",
      "setSnackBar",
    ]),

    activationSync() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/activation-sync/ms`;

      const auth = {
        headers: { Authorization: this.token },
      };

      return this.$axios
        .post(url, {}, auth)
        .then(({ data }) => {
          if (data?.response) {
            const response = data.response;
            this.setCompanyPostponeRoaming(response.postpone_roaming);
          }
        })
        .catch((error) => {
          this.handleError(error);
          console.error("activationSync()", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toggleActivation() {
      this.loading = true;
      const payload = {
        postpone_roaming: !this.postponeRoaming,
      };
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/toggle-activation/ms`;

      const auth = {
        headers: { Authorization: this.token },
      };

      return this.$axios
        .patch(url, payload, auth)
        .then(({ data }) => {
          if (data?.response) {
            const response = data.response;
            this.setCompanyPostponeRoaming(response.postpone_roaming);
          }
        })
        .catch((error) => {
          this.handleError(error);
          console.error("toggleActivation()", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleError(error) {
      this.setCompanyPostponeRoaming(!this.postponeRoaming);
      setTimeout(() => {
        this.setCompanyPostponeRoaming(!this.postponeRoaming);
      }, 50);
      let message = this.$t("activateMicrosoft.dialog.error.unknown");
      if (error.response.data.code === "NOT_MICROSOFT_ADMIN") {
        message = this.$t("activateMicrosoft.dialog.error.notAdmin");
      }
      this.setSnackBar({
        message,
        show: true,
        color: "error",
      });
    },
  },

  async beforeMount() {
    if (this.needSync) {
      await this.activationSync();
    }
  },
};
</script>
