<template>
  <div class="d-flex justify-space-between align-baseline">
    <v-switch
      :color="color"
      inset
      :input-value="value"
      :value="value"
      @change="$emit('change', $event)"
      dense
      class="ms-3 mt-1 mb-4"
      :true-value="true"
      :false-value="false"
      :disabled="disabled"
    >
      <template v-slot:label>
        <div class="d-flex flex-column">
          <div
            :class="`${
              value
                ? 'green--text text--lighten-2'
                : 'red--text text--lighten-2'
            } text-uppercase text-body-1 font-weight-medium`"
          >
            {{ value ? $t("common.active") : $t("common.disabled") }}
          </div>
          <div
            style="white-space: normal"
            class="font-weight-regular text-body-2"
          >
            {{ value ? disable_description : activate_description }}
          </div>
        </div>
      </template>
    </v-switch>
    <div>
      <v-chip
        v-show="recommended"
        small
        color="primary"
        class="font-weight-medium"
        :disabled="disabled"
        >{{ $t("common.recommended") }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveDisabledSwitch",
  props: {
    value: { type: Boolean, default: () => false },
    disable_description: { type: String, default: () => "" },
    activate_description: { type: String, default: () => "" },
    disabled: { type: Boolean, default: () => false },
    recommended: { type: Boolean, default: () => false },
    color: { type: String, default: () => "" },
  },
};
</script>
