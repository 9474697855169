import Vue from "vue";
import "./plugins/axios";
import "@/plugins/firebase";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { initializeHotjar } from "./plugins/hotjar";
import { initializeClarity } from "./plugins/clarity";
import router from "./router";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";
import VueClipboard from "vue-clipboard2";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles
import VueGtag from "vue-gtag";

import DatetimePicker from "vuetify-datetime-picker";
import store from "./store";
import "./plugins/vuetify-money.js";

import Alert from "@/components/base/Alert";
import SimpleDialog from "@/components/base/SimpleDialog";
import HeaderDialog from "@/components/base/HeaderDialog";
import Logo from "@/components/base/Logo";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VSnackbars from "v-snackbars";
import Snackbar from "@/components/general/Snackbar";
import TableHeader from "@/components/base/TableHeader";
import playVideoButton from "@/components/base/playVideoButton";
import DialogHasProductAccess from "@/components/home/dialogs/DialogHasProductAccess.vue";
import UserBadge from "@/components/base/UserBadge";
import TooltipIcon from "@/components/base/TooltipIcon";
import ProductButton from "@/components/base/ProductButton";
import Popover from "@/components/base/Popover";
import HeaderTitle from "@/components/main/shared/HeaderTitle.vue";
import ActionButtons from "@/components/base/ActionButtons.vue";
import InternationalPhone from "@/components/general/InternationalPhone";
import NewsTip from "@/components/base/NewsTip.vue";
import { i18n } from "@/plugins/vue-i18n";

import "@/plugins/vue-tour";

Vue.use(
  VueGtag,
  {
    config: { id: "UA-148164433-3" },
  },
  router
);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.component("PrismEditor", PrismEditor);
Vue.component("Alert", Alert);
Vue.component("SimpleDialog", SimpleDialog);
Vue.component("HeaderDialog", HeaderDialog);
Vue.component("Snackbar", Snackbar);
Vue.component("Logo", Logo);
Vue.component("TableHeader", TableHeader);
Vue.component("v-snackbars", VSnackbars);
Vue.component("v-play-video-button", playVideoButton);
Vue.component("DialogHasProductAccess", DialogHasProductAccess);
Vue.component("UserBadge", UserBadge);
Vue.component("TooltipIcon", TooltipIcon);
Vue.component("ProductButton", ProductButton);
Vue.component("Popover", Popover);
Vue.component("HeaderTitle", HeaderTitle);
Vue.component("ActionButtons", ActionButtons);
Vue.component("InternationalPhone", InternationalPhone);
Vue.component("NewsTip", NewsTip);
// Vue.component("cropper", Cropper);
// Vue.component("bounding-box", BoundingBox);

Vue.use(VueClipboard);

Vue.use(DatetimePicker);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;
Vue.component("v-chart", ECharts);

let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    // Here's the click/touchstart handler
    // (it is registered below)
    handleOutsideClick = (e) => {
      e.stopPropagation();
      // Get the handler method name and the exclude array
      // from the object used in v-closable
      const { handler, exclude } = binding.value;
      // This variable indicates if the clicked element is excluded
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        // We only run this code if we haven't detected
        // any excluded element yet
        if (!clickedOnExcludedEl) {
          // Get the element using the reference name
          const excludedEl = vnode.context.$refs[refName];
          // See if this excluded element
          // is the same element the user just clicked on
          if (excludedEl) {
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });
      // We check to see if the clicked element is not
      // the dialog element and not excluded
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        // If the clicked element is outside the dialog
        // and not the button, then call the outside-click handler
        // from the same component this directive is used in
        vnode.context[handler]();
      }
    };
    // Register click/touchstart event listeners on the whole page
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    // If the element that has v-closable is removed, then
    // unbind click/touchstart listeners from the whole page
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

initializeHotjar();

initializeClarity();

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
