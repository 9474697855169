<template>
  <v-app>
    <v-main :class="$vuetify.theme.dark ? '' : getBackgroundColor">
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
export default {
  computed: {
    getBackgroundColor() {
      if (this.$route.name === "OnboardingSurvey") return "white";

      return this.$route.name === "Login" ? "" : "grey lighten-4";
    },
  },
};
</script>
