const state = () => ({
  onboardingStep: JSON.parse(localStorage.getItem("onboardingStep")) || 1,
  onboardingForms: JSON.parse(localStorage.getItem("onboardingForms")) || {},
  onboardingUser: JSON.parse(localStorage.getItem("onboardingUser")) || {},
  onboardingCreatedUser:
    JSON.parse(localStorage.getItem("onboardingCreatedUser")) || {},
  loadingCheckUser: false,
});

const getters = {
  onboardingStepsNumber: () => 7,
  currentOnboardingStep: (state) => state.onboardingStep,
  onboardingForms: (state) => state.onboardingForms,
  onboardingUser: (state) => state.onboardingUser,
  onboardingCreatedUser: (state) => state.onboardingCreatedUser,
  loadingCheckUser: (state) => state.loadingCheckUser,
};

// actions
const actions = {
  async checkUserById({ commit, dispatch, getters }) {
    commit("setLoadingCheckUser", true);

    const user = await dispatch(
      "getUserByIdGoogle",
      getters.onboardingUser.id_google
    );

    if (user?.key) {
      commit("setOnboardingUser", user);
      commit("addInUsers", user);
      commit("setLoadingCheckUser", false);
    } else {
      setTimeout(async () => {
        await dispatch("checkUserById");
      }, 5000);
    }
  },
};

// mutations
const mutations = {
  setOnboardingStep(state, step) {
    if (step) {
      state.onboardingStep = step;
      localStorage.setItem(
        "onboardingStep",
        JSON.stringify(state.onboardingStep)
      );
    } else {
      state.onboardingStep = 1;
      localStorage.removeItem("onboardingStep");
    }
  },
  setOnboardingForms(state, { label, formData }) {
    state.onboardingForms = {
      ...state.onboardingForms,
      [label]: formData,
    };
    localStorage.setItem(
      "onboardingForms",
      JSON.stringify(state.onboardingForms)
    );
  },
  clearOnboardingForms(state) {
    state.onboardingForms = {};
    localStorage.removeItem("onboardingForms");
  },
  setOnboardingUser(state, user) {
    state.onboardingUser = user || {};
    if (user) {
      localStorage.setItem(
        "onboardingUser",
        JSON.stringify(state.onboardingUser)
      );
    } else {
      localStorage.removeItem("onboardingUser");
    }
  },
  setOnboardingCreatedUser(state, user) {
    state.onboardingCreatedUser = user || {};
    if (user) {
      localStorage.setItem(
        "onboardingCreatedUser",
        JSON.stringify(state.onboardingCreatedUser)
      );
    } else {
      localStorage.removeItem("onboardingCreatedUser");
    }
  },
  setLoadingCheckUser(state, loading) {
    state.loadingCheckUser = loading;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
