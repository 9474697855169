const state = () => ({
  openPostponeRoamingDialog: false,
});

const getters = {
  openPostponeRoamingDialog: (state) => state.openPostponeRoamingDialog,
};

const actions = {};

const mutations = {
  togglePostponeRoamingDialog(state, value) {
    state.openPostponeRoamingDialog = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
