<template>
  <v-card-text align="center">
    <p
      class="text-h6 font-weight-regular mb-5"
      style="white-space: nowrap; text-align: center"
    >
      {{ $t("verification.resolveCaptcha") }}
    </p>
    <p
      class="pt-1"
      style="max-width: 370px; padding: 0px 40px 20px; text-align: center"
    >
      {{ $t("verification.afterResolving") }}
    </p>
    <v-row justify="center">
      <v-col align="center" class="pt-0">
        <v-progress-circular
          v-if="loadingRecaptcha"
          color="white"
          indeterminate
        />
        <div id="recaptcha-container" style="width: 304px; height: 78px" />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { getAuth, RecaptchaVerifier, useDeviceLanguage } from "firebase/auth";

export default {
  name: "VerificationRecaptcha",
  props: {
    status: { type: String, default: () => "" },
  },
  data: () => ({
    loadingRecaptcha: false,
  }),
  computed: {
    showRecaptcha() {
      return !["not-showing", "verified"].includes(this.recaptchaStatus);
    },

    recaptchaStatus: {
      get() {
        return this.status;
      },
      set(value) {
        this.$emit("status-change", value);
      },
    },
  },
  methods: {
    async verifyRecaptcha() {
      if (window.recaptchaVerifier) {
        const widgetId = await window.recaptchaVerifier.render();
        const recaptchaResponse = window.grecaptcha.getResponse(widgetId);
        this.recaptchaStatus =
          recaptchaResponse.length > 0 ? "verified" : "not-verified";
        return this.showRecaptcha;
      }

      this.loadingRecaptcha = true;
      this.recaptchaStatus = "showing";

      const auth = getAuth();
      useDeviceLanguage(auth);

      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            callback: async () => {
              this.recaptchaStatus = "verified";
              setTimeout(() => this.$emit("verified"), 1000);
            },
            "expired-callback": (e) => {
              console.error(e);
              this.loadingRecaptcha = true;
              window.recaptchaVerifier.render().then((widgetId) => {
                window.grecaptcha.reset(widgetId);
                this.loadingRecaptcha = false;
              });
            },
            "error-callback": (e) => {
              console.error(e);
              this.loadingRecaptcha = true;
              window.recaptchaVerifier.render().then((widgetId) => {
                window.grecaptcha.reset(widgetId);
                this.loadingRecaptcha = false;
              });
            },
          },
          auth
        );
      } catch (error) {
        console.error(error);
        // auth/argument-error
        if (error.message.includes("auth/network-request-failed")) {
          setTimeout(this.verifyRecaptcha, 3000);
        } else {
          this.$emit("error", error?.message);
        }
      }

      if (!window.recaptchaVerifier) return this.showRecaptcha;

      await window.recaptchaVerifier.render();

      this.loadingRecaptcha = false;

      return this.showRecaptcha;
    },
  },
  async mounted() {
    await this.verifyRecaptcha();
  },
};
</script>
