import { batchesPartition, isEmptyObject } from "@/helpers/services/utils";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { get, getDatabase, onValue, ref, set, update } from "firebase/database";

function buildQuery(query) {
  let result = "smaller:25M";
  if (query.startDate) result += ` after:${query.startDate.unix()}`;
  if (query.includeSpamAndTrash) result += " in:anywhere";
  return result;
}

const state = () => ({
  emailStep: 1,
  companyEmailTransfers: {},
  readUserEmails: {},
  userToMigrateEmail: null,
  migrateEmailGroup: null,
  emailMessagesIds: [],
  runningEmailProcessPath: "",
  loadingEmailMigration: false,
  loadingEmailsFromGoogle: false,
  emailTransferInProgress: null,
  nextBatch: undefined,
  finishEmailTransfer: false,
});

const gapiClient = () => window.gapi?.client;
const setGAPIToken = (token) => gapiClient().setToken({ access_token: token });

const getters = {
  emailStep: (state) => state.emailStep,
  loadingEmailMigration: (state) => state.loadingEmailMigration,
  emailTransferPath: (_, { company }) => {
    return `email_transfer/${company.main_domain}`.replace(
      /(@|\.|#|\$|\[|\])/g,
      "_"
    );
  },

  tokenToReadEmails: (state) => state.readUserEmails.token,
  tokenExpirationTime: (state) => state.readUserEmails.time,
  userToMigrateEmail: (state) => state.userToMigrateEmail,
  groupToMigrate: (state) => state.migrateEmailGroup,
  emailMessagesIds: (state) => state.emailMessagesIds,
  companyEmailTransfers: (state) => state.companyEmailTransfers,
  emailTransferInProgress: (state) => state.emailTransferInProgress,
  emailTransferInProgressPath: (state) => state.runningEmailProcessPath,
  nextBatchIndex: (state) => state.nextBatch,
  loadingEmailsFromGoogle: (state) => state.loadingEmailsFromGoogle,
  finishEmailTransfer: (state) => state.finishEmailTransfer,
};

async function getMailMessageByID(gmailMessageID) {
  const params = {
    userId: "me",
    format: "raw",
    id: gmailMessageID.id,
  };

  try {
    const response = await gapiClient().gmail.users.messages.get(params);
    return response.result;
  } catch (err) {
    console.error(err);
    return false;
  }
}

function decodeBase64Email(raw) {
  return Buffer.from(raw, "base64").toString("binary");
}

const actions = {
  async getUserTokenToReadEmails({ getters, commit }) {
    if (getters.tokenToReadEmails) {
      const fourMinutes = 1000 * 60 * 4;
      if (Date.now() - getters.tokenExpirationTime < fourMinutes) {
        setGAPIToken(getters.tokenToReadEmails);
        return;
      }
    }

    const userKey = getters.userToMigrateEmail.key;
    const url = `${process.env.VUE_APP_API_BASE_URL}/users/${userKey}/token`;
    const payload = { scopes: [INCREMENTAL_SCOPES.GMAIL_READONLY] };
    const auth = { headers: { Authorization: getters.token } };

    try {
      const response = await this._vm.$axios.post(url, payload, auth);
      commit("setTokenToReadUserEmails", response.data.token);
      setGAPIToken(getters.tokenToReadEmails);
    } catch (err) {
      console.error(err);
    }
  },

  async fetchEmailsFromGoogle({ dispatch, commit }, query) {
    commit("setEmailMessagesIds", []);
    await dispatch("getUserTokenToReadEmails");

    const params = {
      maxResults: 500,
      userId: "me",
      fields: "nextPageToken,messages(id)",
      q: buildQuery(query),
      pageToken: null,
    };

    let messages = [];

    commit("setLoadingEmailsFromGoogle");

    do {
      try {
        const response = await gapiClient().gmail.users.messages.list(params);
        messages = messages.concat(response.result.messages || []);
        commit("setEmailMessagesIds", messages);
        params.pageToken = response.result.nextPageToken;
      } catch (err) {
        console.error(err);
        break;
      }
    } while (params.pageToken);

    commit("setLoadingEmailsFromGoogle", false);
  },

  async uploadEmailBatches({ getters }) {
    if (!getters.userToMigrateEmail || !getters.groupToMigrate) return;

    const user = {
      name: getters.userToMigrateEmail.name,
      email: getters.userToMigrateEmail.email,
      key: getters.userToMigrateEmail.key,
      id_google: getters.userToMigrateEmail.id_google,
    };

    const author = {
      id_google: getters.currentUser.id_google,
      name: getters.currentUser.name,
      email: getters.currentUser.email,
    };

    const db = getDatabase();
    const emailToGroupPath = `${getters.userToMigrateEmail.email}_${getters.groupToMigrate}`;
    const transferMailPath =
      `${getters.emailTransferPath}/${emailToGroupPath}`.replace(
        /(@|\.|#|\$|\[|\])/g,
        "_"
      );

    const db_ref = ref(db, transferMailPath);

    set(db_ref, {
      user,
      group: getters.groupToMigrate,
      author,
      status: "toDo",
      createdAt: new Date().getTime(),
      totalEmails: getters.emailMessagesIds.length,
      migrated: 0,
      failed: 0,
    });

    const emailsBatchPath = `email_transfer/emails/${user.id_google}`;
    const db_batch_ref = ref(db, emailsBatchPath);

    set(db_batch_ref, batchesPartition(getters.emailMessagesIds));
  },

  findNextBatchIndex({ getters, commit }) {
    if (!getters.emailTransferInProgress) return;

    const { totalEmails, failed, migrated } = getters.emailTransferInProgress;
    const totalEmailsMigrated = failed + migrated;
    const batchSize = 500;

    const batches = Array.from(
      { length: Math.ceil(totalEmails / batchSize) },
      (_, i) => (i + 1) * batchSize
    );

    commit(
      "setNextBatchIndex",
      batches.find((batch) => totalEmailsMigrated < batch)
    );
  },

  async getNextBatch({ getters, commit, dispatch }) {
    const db = getDatabase();
    dispatch("findNextBatchIndex");

    const { userToMigrateEmail, nextBatchIndex } = getters;

    if (!userToMigrateEmail) {
      commit("setEmailMessagesIds", []);
      return;
    }

    const path = `email_transfer/emails/${userToMigrateEmail.id_google}/${nextBatchIndex}`;
    const refPath = ref(db, path);
    const snapshot = await get(refPath);

    if (!snapshot.exists()) {
      commit("setEmailMessagesIds", []);
      return;
    }

    commit("setEmailMessagesIds", snapshot.val());
  },

  async updateEmailTransfer({ getters }) {
    const db = getDatabase();

    await update(ref(db), {
      [getters.emailTransferInProgressPath]: getters.emailTransferInProgress,
    });
  },

  async migrateEmailToGroup({ getters, commit }, emailMessage) {
    if (!emailMessage) {
      commit("setIncrementEmailTransferProgress", "failed");
      return;
    }

    const request = {
      method: "POST",
      path: `upload/groups/v1/groups/${getters.groupToMigrate}/archive`,
      params: { uploadType: "media" },
      body: decodeBase64Email(emailMessage.raw),
      headers: {
        "Content-Type": "message/rfc822",
      },
    };

    try {
      const response = await gapiClient().request(request);
      if (response?.result?.responseCode === "SUCCESS") {
        commit("setIncrementEmailTransferProgress");
      } else {
        commit("setIncrementEmailTransferProgress", "failed");
      }
    } catch (err) {
      console.error(err);
      commit("setIncrementEmailTransferProgress", "failed");
    }
  },

  async mapRawEmails({ dispatch }, messageIDs) {
    await dispatch("getUserTokenToReadEmails");

    return await Promise.all(
      messageIDs.map(async (messageID) => await getMailMessageByID(messageID))
    );
  },

  async migrateEmailToGroupParallel({ getters, dispatch }, messagesIDs) {
    if (!getters.tokenToReadEmails || !getters.companyToken) return;

    const emailMessages = await dispatch("mapRawEmails", messagesIDs);

    const scopes = [
      INCREMENTAL_SCOPES.ADMIN_GROUP_MIGRATION,
      INCREMENTAL_SCOPES.GMAIL_READONLY,
    ];
    await await dispatch("getCompanyGAPIToken", scopes);

    return await Promise.all(
      emailMessages.map(
        async (emailMessage) =>
          await dispatch("migrateEmailToGroup", emailMessage)
      )
    );
  },

  async getCompanyEmailTransfers({ getters, commit }) {
    if (!isEmptyObject(getters.companyEmailTransfers)) return;

    const db = getDatabase();
    const db_ref = ref(db, getters.emailTransferPath);

    try {
      onValue(db_ref, (snapshot) => {
        if (snapshot.exists()) {
          commit("setCompanyEmailTransfers", snapshot.val());
        }
      });
    } catch (err) {
      console.error(err);
    }
  },

  async startMailTransferToGroup({ getters, commit, dispatch }) {
    commit("setFinishEmailTransfer", false);
    if (!getters.userToMigrateEmail || !getters.groupToMigrate) return;

    if (!gapiClient()) return;

    const emailToGroupPath =
      `${getters.userToMigrateEmail.email}_${getters.groupToMigrate}`.replace(
        /(@|\.|#|\$|\[|\])/g,
        "_"
      );
    const transferEmailInProgress =
      `${getters.emailTransferPath}/${emailToGroupPath}`.replace(
        /(@|\.|#|\$|\[|\])/g,
        "_"
      );

    commit("setRunningEmailProcessPath", transferEmailInProgress);
    commit(
      "setEmailTransferInProgress",
      getters.companyEmailTransfers[emailToGroupPath]
    );

    if (!getters.emailTransferInProgress) return;

    if (!getters.emailMessagesIds.length) await dispatch("getNextBatch");

    commit("setLoadingEmailMigration", true);

    const batchSize = 5;

    while (
      getters.emailTransferInProgress.failed +
        getters.emailTransferInProgress.migrated !=
      getters.emailTransferInProgress.totalEmails
    ) {
      for (let i = 0; i < getters.emailMessagesIds.length; i += batchSize) {
        let messagesIDsBatch = getters.emailMessagesIds.slice(i, i + batchSize);
        await dispatch("migrateEmailToGroupParallel", messagesIDsBatch);
        await dispatch("updateEmailTransfer");

        if (getters.finishEmailTransfer) break;
      }

      if (getters.finishEmailTransfer) break;

      await dispatch("getNextBatch");

      if (!getters.nextBatchIndex) break;
    }

    commit("setEmailTransferInProgressStatus");
    await dispatch("updateEmailTransfer");
    commit("setFinishEmailTransfer", false);
    commit("setLoadingEmailMigration", false);
    commit("setTokenToReadUserEmails", "");
    commit("setEmailMessagesIds", []);
    commit("setRunningEmailProcessPath");
  },
};

const mutations = {
  setIncrementEmailTransferProgress(state, attribute = "migrated") {
    if (!["migrated", "failed"].includes(attribute)) return;

    state.emailTransferInProgress[attribute]++;
  },

  setEmailTransferInProgress(state, data) {
    state.emailTransferInProgress = data;
  },

  setEmailTransferInProgressStatus(state, status = "FINISHED") {
    state.emailTransferInProgress["status"] = status;
  },

  setRunningEmailProcessPath(state, path = "") {
    state.runningEmailProcessPath = path;
  },

  setCompanyEmailTransfers(state, data = {}) {
    state.companyEmailTransfers = data;
  },

  setEmailStep(state, step) {
    state.emailStep = step;
  },

  startEmailProcess(state, process) {
    state.userToMigrateEmail = process.user;
    state.migrateEmailGroup = process.group;
  },

  setMigrateEmailUser(state, user = null) {
    state.userToMigrateEmail = user;
  },

  setMigrateEmailGroup(state, group = null) {
    state.migrateEmailGroup = group;
  },

  setTokenToReadUserEmails(state, token) {
    state.readUserEmails = { token, time: Date.now() };
  },

  setEmailMessagesIds(state, data) {
    state.emailMessagesIds = data;
  },

  setLoadingEmailMigration(state, loading) {
    state.loadingEmailMigration = loading;
  },

  setNextBatchIndex(state, data) {
    state.nextBatch = data;
  },

  setLoadingEmailsFromGoogle(state, data = true) {
    state.loadingEmailsFromGoogle = data;
  },

  setFinishEmailTransfer(state, data = true) {
    state.finishEmailTransfer = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
