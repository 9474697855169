<template>
  <div>
    <div
      ref="target"
      :class="(showTip ? 'understood-highlight ' : '') + contentClass"
      v-bind="$attrs"
    >
      <slot />
    </div>

    <v-menu
      v-if="hasSlotContent && showTip"
      :activator="$refs.target"
      :value="showTip"
      :top="position === 'top'"
      :right="position === 'right'"
      :bottom="position === 'bottom'"
      :left="position === 'left'"
      offset-y
      :close-on-click="false"
      :close-on-content-click="false"
      content-class="elevation-0"
      :max-width="256"
    >
      <v-slide-x-transition>
        <div
          v-if="showPointer && position === 'bottom'"
          :class="`tip-pointer tip-pointer-${position}`"
          :style="arrowStyle"
        />
      </v-slide-x-transition>
      <v-card ref="menu" color="#331d6e" flat class="pt-1 pb-3 px-1">
        <v-card-title
          class="justify-center white--text pb-2"
          style="text-align: center"
          v-text="title"
        />
        <v-card-text
          class="white--text pb-2"
          style="text-align: center"
          v-text="content"
        />
        <v-card-actions class="justify-center">
          <v-btn
            outlined
            class="text-none white--text"
            v-text="$t(actionTextKey)"
            @click="understood"
          />
        </v-card-actions>
      </v-card>
      <v-slide-x-transition>
        <div
          v-if="showPointer && position === 'top'"
          :class="`tip-pointer tip-pointer-${position}`"
          :style="arrowStyle"
        />
      </v-slide-x-transition>
    </v-menu>
  </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";

const SEEN_ONCE = "SEEN_ONCE";
const SEEN_TWICE = "SEEN_TWICE";
const UNDERSTOOD = "UNDERSTOOD";

export default {
  name: "NewsTip",

  props: {
    position: { type: String, default: () => "bottom" },
    name: { type: String, required: true },
    title: { type: String, required: true },
    content: { type: String, required: true },
    actionTextKey: { type: String, default: () => "common.understood" },
    startDate: { type: Date, required: true },
    showPointer: { type: Boolean, default: () => true },
    order: { type: Number, default: () => 1 },
    contentClass: { type: String, default: () => "" },
    nextTipName: { type: String, default: () => "" },
    ignoreClientRule: { type: Boolean, default: () => false },
  },

  data() {
    return {
      showTip: false,
      showTipTimeout: null,
      timedOut: false,
      timingOut: null,
      hasSeenState: "",
      seenToday: null,
      arrowTimeout: null,
      arrowStyle: {},
      timeToShowNextTip: 1000,
    };
  },

  computed: {
    ...mapGetters(["company"]),

    fullname() {
      return `understood-${this.name}`;
    },

    hasSeenTodayKey() {
      return `understood-${this.name}-today`;
    },

    nextTipFullname() {
      return `understood-${this.nextTipName}`;
    },

    timeToShowTip() {
      return 1000 + (this.order - 1) * 10000;
    },

    twoMonthsRule() {
      const today = moment();
      const twoMonthsFromStart = moment(this.startDate).add(2, "months");
      return today.isSameOrBefore(twoMonthsFromStart);
    },

    oldClientsRule() {
      if (this.ignoreClientRule) {
        return true;
      }
      const start = moment(this.startDate);
      const createdAt = moment.utc(this.company.created_at).locale("pt-br");
      return createdAt.isBefore(start);
    },

    hasNotSeenRule() {
      return this.hasSeenState !== UNDERSTOOD;
    },

    hasNotSeenTodayRule() {
      if (!this.seenToday) {
        return true;
      }
      const today = moment();
      const seenToday = moment(this.seenToday);
      return today.isAfter(seenToday);
    },

    hasSlotContent() {
      return !!this.$slots.default;
    },

    shouldShow() {
      return (
        this.twoMonthsRule &&
        this.oldClientsRule &&
        this.hasNotSeenRule &&
        this.hasNotSeenTodayRule &&
        !this.timedOut &&
        this.hasSlotContent
      );
    },
  },

  methods: {
    saveHasSeenState(value) {
      this.hasSeenState = value;
      window?.localStorage?.setItem?.(this.fullname, this.hasSeenState);
      this.seenToday = moment().endOf("day").toISOString();
      window?.localStorage?.setItem?.(this.hasSeenTodayKey, this.seenToday);
    },

    understood() {
      this.timeToShowNextTip = 100;
      this.saveHasSeenState(UNDERSTOOD);
    },

    stop() {
      if (!this.showTip) {
        return;
      }
      if (!this.hasSeenState) {
        this.saveHasSeenState(SEEN_ONCE);
      } else if (this.hasSeenState === SEEN_ONCE) {
        this.saveHasSeenState(SEEN_TWICE);
      } else if (this.hasSeenState === SEEN_TWICE) {
        this.saveHasSeenState(UNDERSTOOD);
      }
    },

    calculateArrowPosition() {
      this.arrowTimeout = setTimeout(() => {
        this.$nextTick(() => {
          const target = this.$refs.target;
          const menu = this.$refs.menu?.$el;

          if (!target || !menu) return;

          const targetRect = target.getBoundingClientRect();
          const menuRect = menu.getBoundingClientRect();

          let origin, measure;
          if (["top", "bottom"].includes(this.position)) {
            origin = "left";
            measure = "width";
          } else if (["right", "left"].includes(this.position)) {
            origin = "top";
            measure = "height";
          }

          const menuRectstart = menuRect[origin];
          const menuRectend = menuRect[origin] + menuRect[measure];

          const targetRectstart = targetRect[origin];
          const targetRectend = targetRect[origin] + targetRect[measure];

          let distance;

          if (
            menuRectstart <= targetRectstart &&
            menuRectend <= targetRectend
          ) {
            distance = (menuRectend - targetRectstart) / 2;
          } else if (
            targetRectstart <= menuRectstart &&
            targetRectend <= menuRectend
          ) {
            distance = (targetRectend - menuRectstart) / 2;
          } else if (
            menuRectstart <= targetRectstart &&
            targetRectend <= menuRectend
          ) {
            distance = (targetRectend - targetRectstart) / 2;
          } else if (
            targetRectstart <= menuRectstart &&
            menuRectend <= targetRectend
          ) {
            distance = (menuRectend - menuRectstart) / 2;
          }

          this.arrowStyle = { [origin]: `${distance}px` };
        });
      }, 100);
    },

    startTimingOut() {
      this.timingOut = setTimeout(() => {
        this.stop();
        this.timedOut = true;
      }, 10000);
    },

    showNextTip() {
      if (!this.nextTipName) return;
      const nextTip = this.$tours[this.nextTipFullname];
      if (!nextTip) return;
      nextTip.showTipTimeout = setTimeout(() => {
        nextTip.showNow();
      }, this.timeToShowNextTip);
    },

    showNow() {
      if (this.showTipTimeout) {
        clearTimeout(this.showTipTimeout);
      }
      this.showTip = this.shouldShow;
      this.calculateArrowPosition();
      this.startTimingOut();
    },

    showTipDelayed() {
      this.showTipTimeout = setTimeout(() => {
        this.showNow();
      }, this.timeToShowTip);
    },

    hideTip({ destroy } = {}) {
      if (this.timingOut) {
        clearTimeout(this.timingOut);
      }
      if (this.showTipTimeout) {
        clearTimeout(this.showTipTimeout);
      }
      if (this.arrowTimeout) {
        clearTimeout(this.arrowTimeout);
      }
      if (this.$tours[this.fullname]) {
        delete this.$tours[this.fullname];
      }
      this.showTip = this.shouldShow;
      if (!destroy && this.hasSeenState === UNDERSTOOD) {
        this.showNextTip();
      }
    },
  },

  watch: {
    shouldShow: {
      handler() {
        if (this.shouldShow) {
          return this.showTipDelayed();
        }
        this.hideTip();
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.hideTip({ destroy: true });
  },

  beforeMount() {
    this.$tours[this.fullname] = this;
    this.hasSeenState = window?.localStorage?.getItem?.(this.fullname);
    this.seenToday = window?.localStorage?.getItem?.(this.hasSeenTodayKey);
  },
};
</script>

<style scoped>
.tip-pointer {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
}

.tip-pointer-top {
  margin: 0 0.5rem 0.5rem 0.5rem;
  border-width: 0.63rem 0.63rem 0 0.63rem;
  border-color: #331d6e transparent transparent transparent;
}

.tip-pointer-bottom {
  margin: 0.5rem 0.5rem 0 0.5rem;
  border-width: 0 0.63rem 0.63rem 0.63rem;
  border-color: transparent transparent #331d6e transparent;
}

.tip-pointer-right {
  margin: 0.5rem 0.5rem 0.5rem 0;
  border-width: 0.63rem 0 0.63rem 0.63rem;
  border-color: transparent transparent transparent #331d6e;
}

.tip-pointer-left {
  margin: 0.5rem 0 0.5rem 0.5rem;
  border-width: 0.63rem 0.63rem 0.63rem 0;
  border-color: transparent #331d6e transparent transparent;
}

.understood-highlight {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.4);
}
</style>
