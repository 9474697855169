export const links = {
  signFAQ:
    "https://conectasuite.com/perguntas-frequentes-conecta-suite/#h-conecta-sign",
  signTGAsFAQ:
    "https://conectasuite.com/perguntas-frequentes/conecta-sign/como-usar-tags-na-assinatura-de-e-mail/",
  calendarSchedule:
    "https://corp.conectasuite.com/meetings/app-conectasuite/comercial",
  superlogicaClientPage:
    "https://brlgestao.superlogica.net/clients/areadocliente/clientes/",
  commercial_responsable_img:
    "https://lh3.googleusercontent.com/d/1GwqPcfRsJkRRpzuDrmeBUlzal982bE5y?authuser=0",
  profileImage:
    "https://lh3.googleusercontent.com/d/1qHWYtybtQpfuVC_qL_Kw_iwYtXjfLujy?authuser=0",
  linkedInIcon:
    "https://drive.google.com/thumbnail?id=1zHaQexnICcVjr8QgK67i2Z2W9x4k3YQ6&sz=w1000",
  computerScreenIcon:
    "https://drive.google.com/thumbnail?id=1yLg-Jau99PWgGrMu2aEmxjM6QkaFTrNd&sz=w1000",
  webWhiteIcon:
    "https://drive.google.com/thumbnail?id=1T1-Tzji8RsoS_z_9aWt3EbODWeUDhMIf&sz=w1000",
  calendarIcon:
    "https://drive.google.com/thumbnail?id=1oOlBpppasC32qUwUlkoI2p2bM5CdDeJ2&sz=w1000",
  facebookIcon:
    "https://drive.google.com/thumbnail?id=1LYClSyb-UznkxHt9VXKnAGti5zOmAh1v&sz=w1000",
  instagramIcon:
    "https://drive.google.com/thumbnail?id=1R9SMUwxmPIUOF88PRTCwlO6fY2RyuFK6&sz=w1000",
  twitterIcon:
    "https://drive.google.com/thumbnail?id=1Bl4HkXvfn-q6N7CCXIOs3OByfzaX6Rzu&sz=w1000",
  logoImage:
    "https://drive.google.com/thumbnail?id=1ZBpbRRwd5XxPJmE61d6nPH4-xOAzHjms&sz=w1000",
};

export const googleScopesDocLink =
  "https://conectasuite.com/faq/permitir-novos-escopos-google-workspace/";

export const microsoftScopesDocLink =
  "https://confluence.external-share.com/content/705514/conecta_suite_-_como_permitir_acesso_a_novos_escopos_no_painel_azure_da_microsoft";

export const googleAppsPainelLink = `https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;

export const microsoftAppsPainelLink =
  "https://entra.microsoft.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview";
