import { modules } from "@/helpers/variables/modules";
import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import checkout from "./modules/checkout";
import clients from "./modules/clients";
import company from "./modules/company";
import controlIntegration from "./modules/controlIntegration";
import driveMigration from "./modules/driveMigration";
import emailMigration from "./modules/emailMigration";
import googleApi from "./modules/googleApi";
import microsoft from "./modules/microsoft";
import migrationStatus from "./modules/migrationStatus";
import notifications from "./modules/notifications";
import offboardingUser from "./modules/offboardingUser";
import onboardingNewUser from "./modules/onboardingNewUser";
import permissions from "./modules/permissions";
import plans from "./modules/plans";
import resellers from "./modules/resellers";
import routerConfig from "./modules/routerConfig";
import selfcheckout from "./modules/selfcheckout";
import tasks from "./modules/tasks";
import users from "./modules/users";
import workspaceLicense from "./modules/workspaceLicense";

/* Sign */
import general from "./modules/sign/general.js";
import send_as from "./modules/sign/send_as";
import signatures from "./modules/sign/signatures";
import tags from "./modules/sign/tags";
import vacation_messages from "./modules/sign/vacation_messages";

import Vuetify from "@/plugins/vuetify";
import styles from "@/styles/variables.scss";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sidebar: true,
    primaryColor: styles.primarySuite,
    secondaryColor: styles.accentSuite,
    companyProducts: [],
    productName: modules.suite,
    isDev: false,
    loading: false,
    chatLoading: false,
    sidebarMini: false,
    highlight: true,
    snackbar: {
      show: false,
      is_key: true,
      message_key: "",
      message: "",
      timeout: 5000,
      color: "success",
      showCloseButton: false,
    },
    seenHowToApplySign:
      new Number(localStorage.getItem("seenHowToApplySign")) > 0,
    feedbackButtonClick: 0,

    hasAnOpenActionFeedback: false,
    controlFloatingCard: false,
    hasAnOpenDialog: false,
    acceptedDriveScopes: false,
    checkedScopes: {},
    suite_config: {
      maxValueAllowedForCardPayment: 0,
    },
    allLanguages: [
      { title: "Português (Brasil)", lang: "pt-br" },
      { title: "English (US)", lang: "en" },
    ],
    lang: localStorage.getItem("lang") || "pt-br",
    hubChatOpened: false,
  },
  getters: {
    hasOpenedHubChat: (state) => state.hubChatOpened,
    showControlFloatingCard: (state) => state.controlFloatingCard,
    maxValueAllowedForCardPayment: (state) =>
      state.suite_config.maxValueAllowedForCardPayment,
    loading: (state) => state.loading,
    chatLoading: (state) => state.chatLoading,
    hasAcceptedDriveScopes: (state) => state.acceptedDriveScopes,
    primaryColor: (state) => state.primaryColor,
    secondaryColor: (state) => state.secondaryColor,
    companyProducts: (state) => state.companyProducts,
    productName: (state) => state.productName,
    sidebar: (state) => state.sidebar,
    allLanguages: (state) => state.allLanguages,
    isDev: () =>
      process.env.VUE_APP_CLOUD_FUNCTION_DIRECTORY_PROJECT ===
      "dev-conectasuite",
    isDarkMode: () => Vuetify.framework.dark,
    contentWidth: () => {
      if (Vuetify.framework.breakpoint.xl) {
        return 1400;
      } else {
        return "95%";
      }
    },
    pageGap: (state, getters) =>
      !getters.sidebarMini && Vuetify.framework.breakpoint.lgAndUp
        ? "mx-4"
        : "mx-auto",
    sidebarMini: (state) => state.sidebarMini,
    nextPage: () => localStorage.getItem("nextPage"),
    tourLabels: () => {
      return {
        buttonSkip: "Pular tutorial",
        buttonPrevious: "Voltar",
        buttonNext: "Próximo",
        buttonStop: "Finalizar tutorial",
      };
    },
    snackbar: (state) => state.snackbar,
    isShowingSnackbar: (state) => state.snackbar.show,
    snackbarHasMessageKey: (state) => state.snackbar.is_key,
    snackbarMessageKey: (state) => state.snackbar.message_key,
    snackbarMessage: (state) => state.snackbar.message,
    snackbarTimeout: (state) => state.snackbar.timeout,
    snackbarColor: (state) => state.snackbar.color,
    snackbarShowCloseButton: (state) => state.snackbar.showCloseButton,
    seenHowToApplySign: (state) => state.seenHowToApplySign,
    allFAQSeen: (state) => state.seenHowToApplySign,
    feedbackButtonClick: (state) => state.feedbackButtonClick,
    hasAnOpenActionFeedback: (state) => state.hasAnOpenActionFeedback,
    hasAnOpenDialog: (state) => state.hasAnOpenDialog,
    scopeCode: (state) => (scope) => state.checkedScopes[scope],
    lang: (state) => state.lang,
  },

  mutations: {
    setOpenedChat(state, value = true) {
      state.hubChatOpened = value;
    },

    setAcceptedDriveScopes(state, value = false) {
      state.acceptedDriveScopes = value;
    },
    setSidebarView(state, value = null) {
      if (Vuetify.framework.breakpoint.mobile) {
        state.sidebar = value || !state.sidebar;
      } else {
        state.sidebar = true;
        state.sidebarMini = !state.sidebarMini;
      }
    },

    setSidebar(state, value = true) {
      state.sidebar = value;
    },

    closeControlCard(state) {
      state.controlFloatingCard = false;
    },

    toggleControlCard(state) {
      state.controlFloatingCard = !state.controlFloatingCard;
    },

    setPrimaryColor(state, color) {
      state.primaryColor = color;
    },
    removePrimaryColor(state) {
      state.primaryColor = styles.primarySuite;
    },
    setSecondaryColor(state, color) {
      state.secondaryColor = color;
    },
    removeSecondaryColor(state) {
      state.secondaryColor = styles.accentSuite;
    },
    companyProducts(state, products) {
      state.companyProducts = products;
    },
    setProductName(state, productName) {
      state.productName = productName;
    },
    removeProductName(state) {
      state.productName = modules.suite;
    },
    setLoading(state, status = false) {
      state.loading = status;
    },

    setChatLoading(state, status = false) {
      state.chatLoading = status;
    },

    setSidebarMini(state, status) {
      state.sidebarMini = status;
    },

    setSnackBar(state, config) {
      const {
        is_key,
        color,
        message,
        message_key,
        timeout,
        showCloseButton,
        show,
      } = config;

      state.snackbar.is_key = is_key || false;
      state.snackbar.color = color || "success";
      state.snackbar.message = message;
      state.snackbar.message_key = message_key || "unknown";
      state.snackbar.timeout = timeout || 5000;
      state.snackbar.showCloseButton = showCloseButton || false;
      state.snackbar.show = show;
    },

    setShowSnackbar(state, show = true) {
      state.snackbar.show = show;
    },

    setSnackbarIsKey(state, value = false) {
      state.snackbar.is_key = value;
    },

    setSnackbarMessageKey(state, key) {
      state.snackbar.message_key = key;
    },

    setSnackbarMessage(state, message = "") {
      state.snackbar.message = message;
    },

    setSnackbarTimeout(state, timeout) {
      state.snackbar.timeout = timeout ? timeout : -1;
    },

    setSnackbarColor(state, color = "success") {
      state.snackbar.color = color;
    },

    setSnackbarShowCloseButton(state, shoButton = false) {
      state.snackbar.showCloseButton = shoButton;
    },

    setSeen(state, payload) {
      if (payload.id == 2 && payload.new && !state.seenHowToApplySign) {
        state.seenHowToApplySign = new Date().getTime();
        localStorage.setItem("seenHowToApplySign", state.seenHowToApplySign);
      }
    },

    resetSnackbarInfo(state) {
      state.snackbar.show = false;
      state.snackbar.is_key = false;
      state.snackbar.message = "";
      state.snackbar.message_key = "";
      state.snackbar.timeout = 5000;
      state.snackbar.showCloseButton = true;
    },

    feedbackButtonClick(state) {
      state.feedbackButtonClick++;
    },

    setActionFeedBackMode(state, status) {
      state.hasAnOpenActionFeedback = status;
    },

    setDialogMode(state, status) {
      state.hasAnOpenDialog = status;
    },

    setCheckedScopes(state, { scopes, code }) {
      state.checkedScopes = {
        ...state.checkedScopes,
        ...Object.fromEntries(scopes.map((scope) => [scope, code])),
      };
    },

    setMaxValueAllowedForCardPayment(state, value = 0) {
      state.suite_config.maxValueAllowedForCardPayment = value;
    },
    setLocale(state, locale) {
      state.lang = locale;
    },
  },

  actions: {
    companyProducts(context, companyProducts) {
      context.commit("companyProducts", companyProducts);
    },

    checkHasPagePermissions({ state }, product) {
      const resourceList = [
        "reseller",
        "inventory",
        "company_profile",
        "billing",
        "manage_admins",
        "reports",
        "users",
      ];
      const user = state.users.currentUser;

      if (!resourceList.includes(product)) {
        return {
          code: "RESOURCE_NOT_FOUND",
          hasPermission: false,
        };
      }

      if (product === "users") {
        if (
          user.is_google_admin ||
          user.permission["conecta_suite"] === "admin" ||
          user.permission["conecta_sign"] === "admin"
        ) {
          return "SUCCESS";
        }
      }

      if (user.is_google_admin || user.permission[product] === "admin") {
        return "SUCCESS";
      }

      return {
        code: "USER_IS_NOT_ALLOWED_TO_ACCESS_THIS_RESOURCE",
        hasPermission: false,
      };
    },

    checkScopes({ commit, getters }, { scopes, token = null }) {
      if (getters.isCompanyMicrosoft) return;

      const url = `${process.env.VUE_APP_API_BASE_URL}/check-scopes`;
      const headers = {
        headers: {
          Authorization: token || this._vm.token,
        },
      };

      return this._vm.$axios
        .post(url, { scopes: scopes }, headers)
        .then((response) => {
          const data = response.data;
          commit("setCheckedScopes", {
            code: data.code,
            scopes: data.scopes[0],
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          console.error("Vuex: checkScopes()", error);
          commit("setCheckedScopes", error.response.data);
          return Promise.reject(error);
        });
    },
  },
  modules: {
    auth,
    company,
    users,
    clients,
    resellers,
    plans,
    routerConfig,
    permissions,
    notifications,
    microsoft,
    /* Sign */
    general,
    vacation_messages,
    signatures,
    send_as,
    tags,
    checkout,
    selfcheckout,
    onboardingNewUser,
    controlIntegration,
    driveMigration,
    offboardingUser,
    workspaceLicense,
    migrationStatus,
    emailMigration,
    googleApi,
    tasks,
  },
});
