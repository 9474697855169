<template>
  <v-app v-resize="onResize" class="overflow-hidden newDashBackground">
    <div class="drawer-container">
      <div v-if="showControlFloatingCard" class="control-card">
        <div class="d-flex align-center">
          <div class="pa-2 control-logo mr-4">
            <v-img
              width="35"
              contain
              class=""
              src="@/assets/appslogos/icons/icon-control.png"
            />
          </div>

          <div>
            <p class="control-title">Conecta Control</p>
            <a class="control-link" @click="openControlApp">
              <span>{{ $t("common.access") }}</span></a
            >
          </div>
        </div>
      </div>

      <Sidebar
        v-if="!editingTemplate && !userIsOnSelfCheckout"
        @executeRequestActivation="requestActivation"
      />
    </div>

    <!-- NOTIFICATION SIDEBAR -->
    <NotificationsSidebar v-if="currentUser" :color="primaryColor" />

    <Navbar
      @snackbarPurchaseInterest="requestActivation"
      @openSupport="openSupport"
    />

    <v-main :width="bodyWidth" class="overflow-hidden pb-0">
      <router-view />
    </v-main>
    <!-- GLOBAL SNACKBAR -->
    <Snackbar
      :show="isShowingSnackbar"
      :hasKey="snackbarHasMessageKey"
      :message_key="snackbarMessageKey"
      :message="snackbarMessage"
      :timeout="snackbarTimeout"
      :notShowCloseButton="snackbarShowCloseButton"
      :color="snackbarColor"
      @close="resetSnackbarInfo"
      style="z-index: 999"
    />
  </v-app>
</template>

<script>
import NotificationsSidebar from "@/components/main/notifications/NotificationsSidebar.vue";
import Navbar from "@/components/main/newDashboard/Navbar.vue";
import Sidebar from "@/components/main/newDashboard/Sidebar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import { goToControl } from "@/helpers/services/utils";

import { setTrackingPathsHubspot } from "@/helpers/hubspot";

export default {
  name: "PreDashboard",

  components: {
    Sidebar,
    Navbar,
    NotificationsSidebar,
  },

  data: () => ({
    showBlackFriday: false,
    bodyWidth: 0,
  }),

  computed: {
    ...mapGetters([
      "currentRoute",
      "hasBillingPermission",
      "primaryColor",
      "currentUser",
      "isSimulation",
      "snackbar",
      "isShowingSnackbar",
      "snackbarTimeout",
      "snackbarHasMessageKey",
      "snackbarMessage",
      "snackbarColor",
      "snackbarShowCloseButton",
      "snackbarMessageKey",
      "plansInTrialToShowSchedule",
      "feedbackButtonClick",
      "hasEndedTrial",
      "hasSuitePagePermission",
      "hasProcessRunning",
      "emailTransferInProgressPath",
      "showControlFloatingCard",
      "mainDomain",
      "company",
    ]),

    userIsOnSelfCheckout() {
      return (
        this.currentRoute === "BillingUpgradePlan" && this.hasBillingPermission
      );
    },

    editingTemplate() {
      return this.$route.name === "TemplateEdit";
    },

    feedbacksButtons() {
      return document.getElementsByClassName("feedback-button");
    },

    hasMigrationRunning() {
      return this.hasProcessRunning || this.emailTransferInProgressPath;
    },
  },

  watch: {
    showBlackFriday() {
      if (!this.showBlackFriday) {
        this.showBlackFriday = false;
        localStorage.setItem("showBlackFriday", "false");
      }
    },

    "$route.path": async function () {
      if (
        !["ResellerNewPlan", "ResellerClientInfo"].includes(this.$route.name)
      ) {
        localStorage.removeItem("selectedResellerClient", "");
      }
      if (!this.isSimulation) {
        setTrackingPathsHubspot(this.$route.fullPath);
      }
    },

    feedbacksButtons(newValue, oldValue) {
      if (newValue.lenght !== oldValue.lenght) {
        this.removeFeedback();
        this.setFeedback();
      }
    },

    feedbackButtonClick() {
      this.showCollectorDialog();
    },

    hasMigrationRunning(value) {
      if (value) {
        window.addEventListener("beforeunload", this.tasksProcessesHandler);
        window.addEventListener("beforeunload", this.leaveDriveProcessRoom);
        window.addEventListener("unload", this.leaveDriveProcessRoom);
      } else {
        window.removeEventListener("beforeunload", this.tasksProcessesHandler);
        window.removeEventListener("beforeunload", this.leaveDriveProcessRoom);
        window.removeEventListener("unload", this.leaveDriveProcessRoom);
      }
    },
  },

  methods: {
    ...mapMutations([
      "setSidebarView",
      "setLoading",
      "resetSnackbarInfo",
      "setLocale",
      "closeControlCard",
      "setFloatingGuide",
    ]),

    ...mapActions([
      "purchaseInterest",
      "checkBillingPermission",
      "leaveDriveProcessRoom",
      "getUsersMe",
    ]),

    openControlApp() {
      goToControl();
      this.closeControlCard();
    },

    tasksProcessesHandler(event) {
      event.preventDefault();
      event.returnValue = "";
    },

    openSupport() {
      if (this.$route.name != "ContactUs")
        this.$router.push({ name: "ContactUs" });
    },

    loadJiraFeedbackIntegration() {
      var s = document.createElement("script");
      s.src =
        "https://conectanuvem.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=pt-BR&collectorId=12a150ca";
      document.body.appendChild(s);
    },

    setFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function (showCollectorDialog) {
          _this.feedbacksButtons.forEach((element) => {
            element.addEventListener("click", (e) => {
              e.preventDefault();
              showCollectorDialog();
            });
          });
          _this.showCollectorDialog = showCollectorDialog;
        },
      };
    },

    showCollectorDialog() {}, // is defined in [setFeedback()] _this.showCollectorDialog = showCollectorDialog

    removeFeedback() {
      const _this = this;
      window.ATL_JQ_PAGE_PROPS = {
        triggerFunction: function () {
          _this.feedbacksButtons.forEach((element) => {
            element.removeEventListener("click", false);
          });
        },
      };
    },

    onResize() {
      this.bodyWidth = this.sidebarMini
        ? window.innerWidth - 230
        : window.innerWidth - 300;
    },

    requestActivation(product) {
      this.checkBillingPermission()
        .then(() => {
          const analytics = getAnalytics();

          logEvent(analytics, "go_to_self_checkout", {
            click: "trial_bar",
            main_domain: this.mainDomain,
            email: this.currentUser.email,
            description:
              "Clicou no botão 'Contratar plano' do menu de trial na barra superior (navbar)",
          });

          this.$router.push("/billing/add-plan");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // Envio de evento RD informando sobre interesse de aquisição de plano
          this.purchaseInterest(product)
            .then(() => {
              // this.textSuccess = "Solicitação realizada com sucesso!";
              // this.snackbarSuccess = true;
            })
            .catch(() => {
              this.textFailure = "Erro ao realizar a solicitação!";
              this.snackbarFailure = true;
            });
        });
    },

    setPage() {
      const goToPurcharsePage =
        this.hasEndedTrial &&
        this.hasSuitePagePermission["billing"] &&
        !this.isSimulation &&
        this.$route.name !== "BillingUpgradePlan";

      if (goToPurcharsePage) {
        this.$router.push({ name: "BillingUpgradePlan" });
        return;
      }
    },

    saveLocale(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang == "pt-br" ? "pt" : lang;
      localStorage.setItem("lang", lang);
      this.setLocale(lang);
    },
  },

  async beforeMount() {
    if (this.company) {
      this.$router.push({ name: "Home" });
      return;
    }
    this.setLoading(true);
    this.setPage();
    this.saveLocale("pt-br"); // TODO A way to configure lang for users without company

    if (this.$vuetify.breakpoint.mobile) {
      this.setSidebarView(false);
    }
    await Promise.all([
      this.loadJiraFeedbackIntegration(),
      this.getUsersMe(),
    ]).then(() => {
      this.setLoading(false);
    });

    if (this.isSimulation) {
      return;
    }

    this.setFloatingGuide(
      JSON.parse(localStorage.getItem("showFloatingGuide"))
    );
  },

  mounted() {
    this.setFeedback();
  },

  beforeDestroy() {
    this.removeFeedback();
    window.removeEventListener("beforeunload", this.leaveDriveProcessRoom);
    window.removeEventListener("unload", this.leaveDriveProcessRoom);
    window.removeEventListener("beforeunload", this.tasksProcessesHandler);
  },
};
</script>

<style lang="scss" scoped>
.newDashBackground {
  background-color: #f4f3f3;
}

.drawer-container {
  position: relative;
}

.control-card {
  position: fixed;
  top: 10px;
  left: 295px;

  background-color: white;

  border-radius: 12px;
  border: 1px solid #d9d9d9;
  display: inline-block;

  width: 250px;
  padding: 0.4rem;
  z-index: 9999;
}

.control-logo {
  border-radius: 16px;
  background-color: #ebf5fc;
}

.control-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  color: #404040;
  line-height: 17px;
}

.control-link {
  text-decoration: underline;
  text-decoration-color: #3498db;
}

.control-link span {
  color: #3498db;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
}
</style>
