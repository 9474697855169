import CustomIcon from "@/components/base/CustomIcon.vue";

export const phosphorIcons = Object.fromEntries(
  [
    "ph-acorn",
    "ph-address-book",
    "ph-address-book-tabs",
    "ph-air-traffic-control",
    "ph-airplane",
    "ph-airplane-in-flight",
    "ph-airplane-landing",
    "ph-airplane-takeoff",
    "ph-airplane-taxiing",
    "ph-airplane-tilt",
    "ph-airplay",
    "ph-alarm",
    "ph-alien",
    "ph-align-bottom",
    "ph-align-bottom-simple",
    "ph-align-center-horizontal",
    "ph-align-center-horizontal-simple",
    "ph-align-center-vertical",
    "ph-align-center-vertical-simple",
    "ph-align-left",
    "ph-align-left-simple",
    "ph-align-right",
    "ph-align-right-simple",
    "ph-align-top",
    "ph-align-top-simple",
    "ph-amazon-logo",
    "ph-ambulance",
    "ph-anchor",
    "ph-anchor-simple",
    "ph-android-logo",
    "ph-angle",
    "ph-angular-logo",
    "ph-aperture",
    "ph-app-store-logo",
    "ph-app-window",
    "ph-apple-logo",
    "ph-apple-podcasts-logo",
    "ph-approximate-equals",
    "ph-archive",
    "ph-armchair",
    "ph-arrow-arc-left",
    "ph-arrow-arc-right",
    "ph-arrow-bend-double-up-left",
    "ph-arrow-bend-double-up-right",
    "ph-arrow-bend-down-left",
    "ph-arrow-bend-down-right",
    "ph-arrow-bend-left-down",
    "ph-arrow-bend-left-up",
    "ph-arrow-bend-right-down",
    "ph-arrow-bend-right-up",
    "ph-arrow-bend-up-left",
    "ph-arrow-bend-up-right",
    "ph-arrow-circle-down",
    "ph-arrow-circle-down-left",
    "ph-arrow-circle-down-right",
    "ph-arrow-circle-left",
    "ph-arrow-circle-right",
    "ph-arrow-circle-up",
    "ph-arrow-circle-up-left",
    "ph-arrow-circle-up-right",
    "ph-arrow-clockwise",
    "ph-arrow-counter-clockwise",
    "ph-arrow-down",
    "ph-arrow-down-left",
    "ph-arrow-down-right",
    "ph-arrow-elbow-down-left",
    "ph-arrow-elbow-down-right",
    "ph-arrow-elbow-left",
    "ph-arrow-elbow-left-down",
    "ph-arrow-elbow-left-up",
    "ph-arrow-elbow-right",
    "ph-arrow-elbow-right-down",
    "ph-arrow-elbow-right-up",
    "ph-arrow-elbow-up-left",
    "ph-arrow-elbow-up-right",
    "ph-arrow-fat-down",
    "ph-arrow-fat-left",
    "ph-arrow-fat-line-down",
    "ph-arrow-fat-line-left",
    "ph-arrow-fat-line-right",
    "ph-arrow-fat-line-up",
    "ph-arrow-fat-lines-down",
    "ph-arrow-fat-lines-left",
    "ph-arrow-fat-lines-right",
    "ph-arrow-fat-lines-up",
    "ph-arrow-fat-right",
    "ph-arrow-fat-up",
    "ph-arrow-left",
    "ph-arrow-line-down",
    "ph-arrow-line-down-left",
    "ph-arrow-line-down-right",
    "ph-arrow-line-left",
    "ph-arrow-line-right",
    "ph-arrow-line-up",
    "ph-arrow-line-up-left",
    "ph-arrow-line-up-right",
    "ph-arrow-right",
    "ph-arrow-square-down",
    "ph-arrow-square-down-left",
    "ph-arrow-square-down-right",
    "ph-arrow-square-in",
    "ph-arrow-square-left",
    "ph-arrow-square-out",
    "ph-arrow-square-right",
    "ph-arrow-square-up",
    "ph-arrow-square-up-left",
    "ph-arrow-square-up-right",
    "ph-arrow-u-down-left",
    "ph-arrow-u-down-right",
    "ph-arrow-u-left-down",
    "ph-arrow-u-left-up",
    "ph-arrow-u-right-down",
    "ph-arrow-u-right-up",
    "ph-arrow-u-up-left",
    "ph-arrow-u-up-right",
    "ph-arrow-up",
    "ph-arrow-up-left",
    "ph-arrow-up-right",
    "ph-arrows-clockwise",
    "ph-arrows-counter-clockwise",
    "ph-arrows-down-up",
    "ph-arrows-horizontal",
    "ph-arrows-in",
    "ph-arrows-in-cardinal",
    "ph-arrows-in-line-horizontal",
    "ph-arrows-in-line-vertical",
    "ph-arrows-in-simple",
    "ph-arrows-left-right",
    "ph-arrows-merge",
    "ph-arrows-out",
    "ph-arrows-out-cardinal",
    "ph-arrows-out-line-horizontal",
    "ph-arrows-out-line-vertical",
    "ph-arrows-out-simple",
    "ph-arrows-split",
    "ph-arrows-vertical",
    "ph-article",
    "ph-article-medium",
    "ph-article-ny-times",
    "ph-asclepius",
    "ph-caduceus",
    "ph-asterisk",
    "ph-asterisk-simple",
    "ph-at",
    "ph-atom",
    "ph-avocado",
    "ph-axe",
    "ph-baby",
    "ph-baby-carriage",
    "ph-backpack",
    "ph-backspace",
    "ph-bag",
    "ph-bag-simple",
    "ph-balloon",
    "ph-bandaids",
    "ph-bank",
    "ph-barbell",
    "ph-barcode",
    "ph-barn",
    "ph-barricade",
    "ph-baseball",
    "ph-baseball-cap",
    "ph-baseball-helmet",
    "ph-basket",
    "ph-basketball",
    "ph-bathtub",
    "ph-battery-charging",
    "ph-battery-charging-vertical",
    "ph-battery-empty",
    "ph-battery-full",
    "ph-battery-high",
    "ph-battery-low",
    "ph-battery-medium",
    "ph-battery-plus",
    "ph-battery-plus-vertical",
    "ph-battery-vertical-empty",
    "ph-battery-vertical-full",
    "ph-battery-vertical-high",
    "ph-battery-vertical-low",
    "ph-battery-vertical-medium",
    "ph-battery-warning",
    "ph-battery-warning-vertical",
    "ph-beach-ball",
    "ph-beanie",
    "ph-bed",
    "ph-beer-bottle",
    "ph-beer-stein",
    "ph-behance-logo",
    "ph-bell",
    "ph-bell-ringing",
    "ph-bell-simple",
    "ph-bell-simple-ringing",
    "ph-bell-simple-slash",
    "ph-bell-simple-z",
    "ph-bell-slash",
    "ph-bell-z",
    "ph-belt",
    "ph-bezier-curve",
    "ph-bicycle",
    "ph-binary",
    "ph-binoculars",
    "ph-biohazard",
    "ph-bird",
    "ph-blueprint",
    "ph-bluetooth",
    "ph-bluetooth-connected",
    "ph-bluetooth-slash",
    "ph-bluetooth-x",
    "ph-boat",
    "ph-bomb",
    "ph-bone",
    "ph-book",
    "ph-book-bookmark",
    "ph-book-open",
    "ph-book-open-text",
    "ph-book-open-user",
    "ph-bookmark",
    "ph-bookmark-simple",
    "ph-bookmarks",
    "ph-bookmarks-simple",
    "ph-books",
    "ph-boot",
    "ph-boules",
    "ph-bounding-box",
    "ph-bowl-food",
    "ph-bowl-steam",
    "ph-bowling-ball",
    "ph-box-arrow-down",
    "ph-archive-box",
    "ph-box-arrow-up",
    "ph-boxing-glove",
    "ph-brackets-angle",
    "ph-brackets-curly",
    "ph-brackets-round",
    "ph-brackets-square",
    "ph-brain",
    "ph-brandy",
    "ph-bread",
    "ph-bridge",
    "ph-briefcase",
    "ph-briefcase-metal",
    "ph-broadcast",
    "ph-broom",
    "ph-browser",
    "ph-browsers",
    "ph-bug",
    "ph-bug-beetle",
    "ph-bug-droid",
    "ph-building",
    "ph-building-apartment",
    "ph-building-office",
    "ph-buildings",
    "ph-bulldozer",
    "ph-bus",
    "ph-butterfly",
    "ph-cable-car",
    "ph-cactus",
    "ph-cake",
    "ph-calculator",
    "ph-calendar",
    "ph-calendar-blank",
    "ph-calendar-check",
    "ph-calendar-dot",
    "ph-calendar-dots",
    "ph-calendar-heart",
    "ph-calendar-minus",
    "ph-calendar-plus",
    "ph-calendar-slash",
    "ph-calendar-star",
    "ph-calendar-x",
    "ph-call-bell",
    "ph-camera",
    "ph-camera-plus",
    "ph-camera-rotate",
    "ph-camera-slash",
    "ph-campfire",
    "ph-car",
    "ph-car-battery",
    "ph-car-profile",
    "ph-car-simple",
    "ph-cardholder",
    "ph-cards",
    "ph-cards-three",
    "ph-caret-circle-double-down",
    "ph-caret-circle-double-left",
    "ph-caret-circle-double-right",
    "ph-caret-circle-double-up",
    "ph-caret-circle-down",
    "ph-caret-circle-left",
    "ph-caret-circle-right",
    "ph-caret-circle-up",
    "ph-caret-circle-up-down",
    "ph-caret-double-down",
    "ph-caret-double-left",
    "ph-caret-double-right",
    "ph-caret-double-up",
    "ph-caret-down",
    "ph-caret-left",
    "ph-caret-line-down",
    "ph-caret-line-left",
    "ph-caret-line-right",
    "ph-caret-line-up",
    "ph-caret-right",
    "ph-caret-up",
    "ph-caret-up-down",
    "ph-carrot",
    "ph-cash-register",
    "ph-cassette-tape",
    "ph-castle-turret",
    "ph-cat",
    "ph-cell-signal-full",
    "ph-cell-signal-high",
    "ph-cell-signal-low",
    "ph-cell-signal-medium",
    "ph-cell-signal-none",
    "ph-cell-signal-slash",
    "ph-cell-signal-x",
    "ph-cell-tower",
    "ph-certificate",
    "ph-chair",
    "ph-chalkboard",
    "ph-chalkboard-simple",
    "ph-chalkboard-teacher",
    "ph-champagne",
    "ph-charging-station",
    "ph-chart-bar",
    "ph-chart-bar-horizontal",
    "ph-chart-donut",
    "ph-chart-line",
    "ph-chart-line-down",
    "ph-chart-line-up",
    "ph-chart-pie",
    "ph-chart-pie-slice",
    "ph-chart-polar",
    "ph-chart-scatter",
    "ph-chat",
    "ph-chat-centered",
    "ph-chat-centered-dots",
    "ph-chat-centered-slash",
    "ph-chat-centered-text",
    "ph-chat-circle",
    "ph-chat-circle-dots",
    "ph-chat-circle-slash",
    "ph-chat-circle-text",
    "ph-chat-dots",
    "ph-chat-slash",
    "ph-chat-teardrop",
    "ph-chat-teardrop-dots",
    "ph-chat-teardrop-slash",
    "ph-chat-teardrop-text",
    "ph-chat-text",
    "ph-chats",
    "ph-chats-circle",
    "ph-chats-teardrop",
    "ph-check",
    "ph-check-circle",
    "ph-check-fat",
    "ph-check-square",
    "ph-check-square-offset",
    "ph-checkerboard",
    "ph-checks",
    "ph-cheers",
    "ph-cheese",
    "ph-chef-hat",
    "ph-cherries",
    "ph-church",
    "ph-cigarette",
    "ph-cigarette-slash",
    "ph-circle",
    "ph-circle-dashed",
    "ph-circle-half",
    "ph-circle-half-tilt",
    "ph-circle-notch",
    "ph-circles-four",
    "ph-circles-three",
    "ph-circles-three-plus",
    "ph-circuitry",
    "ph-city",
    "ph-clipboard",
    "ph-clipboard-text",
    "ph-clock",
    "ph-clock-afternoon",
    "ph-clock-clockwise",
    "ph-clock-countdown",
    "ph-clock-counter-clockwise",
    "ph-clock-user",
    "ph-closed-captioning",
    "ph-cloud",
    "ph-cloud-arrow-down",
    "ph-cloud-arrow-up",
    "ph-cloud-check",
    "ph-cloud-fog",
    "ph-cloud-lightning",
    "ph-cloud-moon",
    "ph-cloud-rain",
    "ph-cloud-slash",
    "ph-cloud-snow",
    "ph-cloud-sun",
    "ph-cloud-warning",
    "ph-cloud-x",
    "ph-clover",
    "ph-club",
    "ph-coat-hanger",
    "ph-coda-logo",
    "ph-code",
    "ph-code-block",
    "ph-code-simple",
    "ph-codepen-logo",
    "ph-codesandbox-logo",
    "ph-coffee",
    "ph-coffee-bean",
    "ph-coin",
    "ph-coin-vertical",
    "ph-coins",
    "ph-columns",
    "ph-columns-plus-left",
    "ph-columns-plus-right",
    "ph-command",
    "ph-compass",
    "ph-compass-rose",
    "ph-compass-tool",
    "ph-computer-tower",
    "ph-confetti",
    "ph-contactless-payment",
    "ph-control",
    "ph-cookie",
    "ph-cooking-pot",
    "ph-copy",
    "ph-copy-simple",
    "ph-copyleft",
    "ph-copyright",
    "ph-corners-in",
    "ph-corners-out",
    "ph-couch",
    "ph-court-basketball",
    "ph-cow",
    "ph-cowboy-hat",
    "ph-cpu",
    "ph-crane",
    "ph-crane-tower",
    "ph-credit-card",
    "ph-cricket",
    "ph-crop",
    "ph-cross",
    "ph-crosshair",
    "ph-crosshair-simple",
    "ph-crown",
    "ph-crown-cross",
    "ph-crown-simple",
    "ph-cube",
    "ph-cube-focus",
    "ph-cube-transparent",
    "ph-currency-btc",
    "ph-currency-circle-dollar",
    "ph-currency-cny",
    "ph-currency-dollar",
    "ph-currency-dollar-simple",
    "ph-currency-eth",
    "ph-currency-eur",
    "ph-currency-gbp",
    "ph-currency-inr",
    "ph-currency-jpy",
    "ph-currency-krw",
    "ph-currency-kzt",
    "ph-currency-ngn",
    "ph-currency-rub",
    "ph-cursor",
    "ph-cursor-click",
    "ph-cursor-text",
    "ph-cylinder",
    "ph-database",
    "ph-desk",
    "ph-desktop",
    "ph-desktop-tower",
    "ph-detective",
    "ph-dev-to-logo",
    "ph-device-mobile",
    "ph-device-mobile-camera",
    "ph-device-mobile-slash",
    "ph-device-mobile-speaker",
    "ph-device-rotate",
    "ph-device-tablet",
    "ph-device-tablet-camera",
    "ph-device-tablet-speaker",
    "ph-devices",
    "ph-diamond",
    "ph-diamonds-four",
    "ph-dice-five",
    "ph-dice-four",
    "ph-dice-one",
    "ph-dice-six",
    "ph-dice-three",
    "ph-dice-two",
    "ph-disc",
    "ph-disco-ball",
    "ph-discord-logo",
    "ph-divide",
    "ph-dna",
    "ph-dog",
    "ph-door",
    "ph-door-open",
    "ph-dot",
    "ph-dot-outline",
    "ph-dots-nine",
    "ph-dots-six",
    "ph-dots-six-vertical",
    "ph-dots-three",
    "ph-dots-three-circle",
    "ph-dots-three-circle-vertical",
    "ph-dots-three-outline",
    "ph-dots-three-outline-vertical",
    "ph-dots-three-vertical",
    "ph-download",
    "ph-download-simple",
    "ph-dress",
    "ph-dresser",
    "ph-dribbble-logo",
    "ph-drone",
    "ph-drop",
    "ph-drop-half",
    "ph-drop-half-bottom",
    "ph-drop-simple",
    "ph-drop-slash",
    "ph-dropbox-logo",
    "ph-ear",
    "ph-ear-slash",
    "ph-egg",
    "ph-egg-crack",
    "ph-eject",
    "ph-eject-simple",
    "ph-elevator",
    "ph-empty",
    "ph-engine",
    "ph-envelope",
    "ph-envelope-open",
    "ph-envelope-simple",
    "ph-envelope-simple-open",
    "ph-equalizer",
    "ph-equals",
    "ph-eraser",
    "ph-escalator-down",
    "ph-escalator-up",
    "ph-exam",
    "ph-exclamation-mark",
    "ph-exclude",
    "ph-exclude-square",
    "ph-export",
    "ph-eye",
    "ph-eye-closed",
    "ph-eye-slash",
    "ph-eyedropper",
    "ph-eyedropper-sample",
    "ph-eyeglasses",
    "ph-eyes",
    "ph-face-mask",
    "ph-facebook-logo",
    "ph-factory",
    "ph-faders",
    "ph-faders-horizontal",
    "ph-fallout-shelter",
    "ph-fan",
    "ph-farm",
    "ph-fast-forward",
    "ph-fast-forward-circle",
    "ph-feather",
    "ph-fediverse-logo",
    "ph-figma-logo",
    "ph-file",
    "ph-file-archive",
    "ph-file-arrow-down",
    "ph-file-arrow-up",
    "ph-file-audio",
    "ph-file-c",
    "ph-file-c-sharp",
    "ph-file-cloud",
    "ph-file-code",
    "ph-file-cpp",
    "ph-file-css",
    "ph-file-csv",
    "ph-file-dashed",
    "ph-file-dotted",
    "ph-file-doc",
    "ph-file-html",
    "ph-file-image",
    "ph-file-ini",
    "ph-file-jpg",
    "ph-file-js",
    "ph-file-jsx",
    "ph-file-lock",
    "ph-file-magnifying-glass",
    "ph-file-search",
    "ph-file-md",
    "ph-file-minus",
    "ph-file-pdf",
    "ph-file-plus",
    "ph-file-png",
    "ph-file-ppt",
    "ph-file-py",
    "ph-file-rs",
    "ph-file-sql",
    "ph-file-svg",
    "ph-file-text",
    "ph-file-ts",
    "ph-file-tsx",
    "ph-file-txt",
    "ph-file-video",
    "ph-file-vue",
    "ph-file-x",
    "ph-file-xls",
    "ph-file-zip",
    "ph-files",
    "ph-film-reel",
    "ph-film-script",
    "ph-film-slate",
    "ph-film-strip",
    "ph-fingerprint",
    "ph-fingerprint-simple",
    "ph-finn-the-human",
    "ph-fire",
    "ph-fire-extinguisher",
    "ph-fire-simple",
    "ph-fire-truck",
    "ph-first-aid",
    "ph-first-aid-kit",
    "ph-fish",
    "ph-fish-simple",
    "ph-flag",
    "ph-flag-banner",
    "ph-flag-banner-fold",
    "ph-flag-checkered",
    "ph-flag-pennant",
    "ph-flame",
    "ph-flashlight",
    "ph-flask",
    "ph-flip-horizontal",
    "ph-flip-vertical",
    "ph-floppy-disk",
    "ph-floppy-disk-back",
    "ph-flow-arrow",
    "ph-flower",
    "ph-flower-lotus",
    "ph-flower-tulip",
    "ph-flying-saucer",
    "ph-folder",
    "ph-folder-notch",
    "ph-folder-dashed",
    "ph-folder-dotted",
    "ph-folder-lock",
    "ph-folder-minus",
    "ph-folder-notch-minus",
    "ph-folder-open",
    "ph-folder-notch-open",
    "ph-folder-plus",
    "ph-folder-notch-plus",
    "ph-folder-simple",
    "ph-folder-simple-dashed",
    "ph-folder-simple-dotted",
    "ph-folder-simple-lock",
    "ph-folder-simple-minus",
    "ph-folder-simple-plus",
    "ph-folder-simple-star",
    "ph-folder-simple-user",
    "ph-folder-star",
    "ph-folder-user",
    "ph-folders",
    "ph-football",
    "ph-football-helmet",
    "ph-footprints",
    "ph-fork-knife",
    "ph-four-k",
    "ph-frame-corners",
    "ph-framer-logo",
    "ph-function",
    "ph-funnel",
    "ph-funnel-simple",
    "ph-funnel-simple-x",
    "ph-funnel-x",
    "ph-game-controller",
    "ph-garage",
    "ph-gas-can",
    "ph-gas-pump",
    "ph-gauge",
    "ph-gavel",
    "ph-gear",
    "ph-gear-fine",
    "ph-gear-six",
    "ph-gender-female",
    "ph-gender-intersex",
    "ph-gender-male",
    "ph-gender-neuter",
    "ph-gender-nonbinary",
    "ph-gender-transgender",
    "ph-ghost",
    "ph-gif",
    "ph-gift",
    "ph-git-branch",
    "ph-git-commit",
    "ph-git-diff",
    "ph-git-fork",
    "ph-git-merge",
    "ph-git-pull-request",
    "ph-github-logo",
    "ph-gitlab-logo",
    "ph-gitlab-logo-simple",
    "ph-globe",
    "ph-globe-hemisphere-east",
    "ph-globe-hemisphere-west",
    "ph-globe-simple",
    "ph-globe-simple-x",
    "ph-globe-stand",
    "ph-globe-x",
    "ph-goggles",
    "ph-golf",
    "ph-goodreads-logo",
    "ph-google-cardboard-logo",
    "ph-google-chrome-logo",
    "ph-google-drive-logo",
    "ph-google-logo",
    "ph-google-photos-logo",
    "ph-google-play-logo",
    "ph-google-podcasts-logo",
    "ph-gps",
    "ph-gps-fix",
    "ph-gps-slash",
    "ph-gradient",
    "ph-graduation-cap",
    "ph-grains",
    "ph-grains-slash",
    "ph-graph",
    "ph-graphics-card",
    "ph-greater-than",
    "ph-greater-than-or-equal",
    "ph-grid-four",
    "ph-grid-nine",
    "ph-guitar",
    "ph-hair-dryer",
    "ph-hamburger",
    "ph-hammer",
    "ph-hand",
    "ph-hand-arrow-down",
    "ph-hand-arrow-up",
    "ph-hand-coins",
    "ph-hand-deposit",
    "ph-hand-eye",
    "ph-hand-fist",
    "ph-hand-grabbing",
    "ph-hand-heart",
    "ph-hand-palm",
    "ph-hand-peace",
    "ph-hand-pointing",
    "ph-hand-soap",
    "ph-hand-swipe-left",
    "ph-hand-swipe-right",
    "ph-hand-tap",
    "ph-hand-waving",
    "ph-hand-withdraw",
    "ph-handbag",
    "ph-handbag-simple",
    "ph-hands-clapping",
    "ph-hands-praying",
    "ph-handshake",
    "ph-hard-drive",
    "ph-hard-drives",
    "ph-hard-hat",
    "ph-hash",
    "ph-hash-straight",
    "ph-head-circuit",
    "ph-headlights",
    "ph-headphones",
    "ph-headset",
    "ph-heart",
    "ph-heart-break",
    "ph-heart-half",
    "ph-heart-straight",
    "ph-heart-straight-break",
    "ph-heartbeat",
    "ph-hexagon",
    "ph-high-definition",
    "ph-high-heel",
    "ph-highlighter",
    "ph-highlighter-circle",
    "ph-hockey",
    "ph-hoodie",
    "ph-horse",
    "ph-hospital",
    "ph-hourglass",
    "ph-hourglass-high",
    "ph-hourglass-low",
    "ph-hourglass-medium",
    "ph-hourglass-simple",
    "ph-hourglass-simple-high",
    "ph-hourglass-simple-low",
    "ph-hourglass-simple-medium",
    "ph-house",
    "ph-house-line",
    "ph-house-simple",
    "ph-hurricane",
    "ph-ice-cream",
    "ph-identification-badge",
    "ph-identification-card",
    "ph-image",
    "ph-image-broken",
    "ph-image-square",
    "ph-images",
    "ph-images-square",
    "ph-infinity",
    "ph-lemniscate",
    "ph-info",
    "ph-instagram-logo",
    "ph-intersect",
    "ph-intersect-square",
    "ph-intersect-three",
    "ph-intersection",
    "ph-invoice",
    "ph-island",
    "ph-jar",
    "ph-jar-label",
    "ph-jeep",
    "ph-joystick",
    "ph-kanban",
    "ph-key",
    "ph-key-return",
    "ph-keyboard",
    "ph-keyhole",
    "ph-knife",
    "ph-ladder",
    "ph-ladder-simple",
    "ph-lamp",
    "ph-lamp-pendant",
    "ph-laptop",
    "ph-lasso",
    "ph-lastfm-logo",
    "ph-layout",
    "ph-leaf",
    "ph-lectern",
    "ph-lego",
    "ph-lego-smiley",
    "ph-less-than",
    "ph-less-than-or-equal",
    "ph-letter-circle-h",
    "ph-letter-circle-p",
    "ph-letter-circle-v",
    "ph-lifebuoy",
    "ph-lightbulb",
    "ph-lightbulb-filament",
    "ph-lighthouse",
    "ph-lightning",
    "ph-lightning-a",
    "ph-lightning-slash",
    "ph-line-segment",
    "ph-line-segments",
    "ph-line-vertical",
    "ph-link",
    "ph-link-break",
    "ph-link-simple",
    "ph-link-simple-break",
    "ph-link-simple-horizontal",
    "ph-link-simple-horizontal-break",
    "ph-linkedin-logo",
    "ph-linktree-logo",
    "ph-linux-logo",
    "ph-list",
    "ph-list-bullets",
    "ph-list-checks",
    "ph-list-dashes",
    "ph-list-heart",
    "ph-list-magnifying-glass",
    "ph-list-numbers",
    "ph-list-plus",
    "ph-list-star",
    "ph-lock",
    "ph-lock-key",
    "ph-lock-key-open",
    "ph-lock-laminated",
    "ph-lock-laminated-open",
    "ph-lock-open",
    "ph-lock-simple",
    "ph-lock-simple-open",
    "ph-lockers",
    "ph-log",
    "ph-magic-wand",
    "ph-magnet",
    "ph-magnet-straight",
    "ph-magnifying-glass",
    "ph-magnifying-glass-minus",
    "ph-magnifying-glass-plus",
    "ph-mailbox",
    "ph-map-pin",
    "ph-map-pin-area",
    "ph-map-pin-line",
    "ph-map-pin-plus",
    "ph-map-pin-simple",
    "ph-map-pin-simple-area",
    "ph-map-pin-simple-line",
    "ph-map-trifold",
    "ph-markdown-logo",
    "ph-marker-circle",
    "ph-martini",
    "ph-mask-happy",
    "ph-mask-sad",
    "ph-mastodon-logo",
    "ph-math-operations",
    "ph-matrix-logo",
    "ph-medal",
    "ph-medal-military",
    "ph-medium-logo",
    "ph-megaphone",
    "ph-megaphone-simple",
    "ph-member-of",
    "ph-memory",
    "ph-messenger-logo",
    "ph-meta-logo",
    "ph-meteor",
    "ph-metronome",
    "ph-microphone",
    "ph-microphone-slash",
    "ph-microphone-stage",
    "ph-microscope",
    "ph-microsoft-excel-logo",
    "ph-microsoft-outlook-logo",
    "ph-microsoft-powerpoint-logo",
    "ph-microsoft-teams-logo",
    "ph-microsoft-word-logo",
    "ph-minus",
    "ph-minus-circle",
    "ph-minus-square",
    "ph-money",
    "ph-money-wavy",
    "ph-monitor",
    "ph-monitor-arrow-up",
    "ph-monitor-play",
    "ph-moon",
    "ph-moon-stars",
    "ph-moped",
    "ph-moped-front",
    "ph-mosque",
    "ph-motorcycle",
    "ph-mountains",
    "ph-mouse",
    "ph-mouse-left-click",
    "ph-mouse-middle-click",
    "ph-mouse-right-click",
    "ph-mouse-scroll",
    "ph-mouse-simple",
    "ph-music-note",
    "ph-music-note-simple",
    "ph-music-notes",
    "ph-music-notes-minus",
    "ph-music-notes-plus",
    "ph-music-notes-simple",
    "ph-navigation-arrow",
    "ph-needle",
    "ph-network",
    "ph-network-slash",
    "ph-network-x",
    "ph-newspaper",
    "ph-newspaper-clipping",
    "ph-not-equals",
    "ph-not-member-of",
    "ph-not-subset-of",
    "ph-not-superset-of",
    "ph-notches",
    "ph-note",
    "ph-note-blank",
    "ph-note-pencil",
    "ph-notebook",
    "ph-notepad",
    "ph-notification",
    "ph-notion-logo",
    "ph-nuclear-plant",
    "ph-number-circle-eight",
    "ph-number-circle-five",
    "ph-number-circle-four",
    "ph-number-circle-nine",
    "ph-number-circle-one",
    "ph-number-circle-seven",
    "ph-number-circle-six",
    "ph-number-circle-three",
    "ph-number-circle-two",
    "ph-number-circle-zero",
    "ph-number-eight",
    "ph-number-five",
    "ph-number-four",
    "ph-number-nine",
    "ph-number-one",
    "ph-number-seven",
    "ph-number-six",
    "ph-number-square-eight",
    "ph-number-square-five",
    "ph-number-square-four",
    "ph-number-square-nine",
    "ph-number-square-one",
    "ph-number-square-seven",
    "ph-number-square-six",
    "ph-number-square-three",
    "ph-number-square-two",
    "ph-number-square-zero",
    "ph-number-three",
    "ph-number-two",
    "ph-number-zero",
    "ph-numpad",
    "ph-nut",
    "ph-ny-times-logo",
    "ph-octagon",
    "ph-office-chair",
    "ph-onigiri",
    "ph-open-ai-logo",
    "ph-option",
    "ph-orange",
    "ph-orange-slice",
    "ph-oven",
    "ph-package",
    "ph-paint-brush",
    "ph-paint-brush-broad",
    "ph-paint-brush-household",
    "ph-paint-bucket",
    "ph-paint-roller",
    "ph-palette",
    "ph-panorama",
    "ph-pants",
    "ph-paper-plane",
    "ph-paper-plane-right",
    "ph-paper-plane-tilt",
    "ph-paperclip",
    "ph-paperclip-horizontal",
    "ph-parachute",
    "ph-paragraph",
    "ph-parallelogram",
    "ph-park",
    "ph-password",
    "ph-path",
    "ph-patreon-logo",
    "ph-pause",
    "ph-pause-circle",
    "ph-paw-print",
    "ph-paypal-logo",
    "ph-peace",
    "ph-pen",
    "ph-pen-nib",
    "ph-pen-nib-straight",
    "ph-pencil",
    "ph-pencil-circle",
    "ph-pencil-line",
    "ph-pencil-ruler",
    "ph-pencil-simple",
    "ph-pencil-simple-line",
    "ph-pencil-simple-slash",
    "ph-pencil-slash",
    "ph-pentagon",
    "ph-pentagram",
    "ph-pepper",
    "ph-percent",
    "ph-person",
    "ph-person-arms-spread",
    "ph-person-simple",
    "ph-person-simple-bike",
    "ph-person-simple-circle",
    "ph-person-simple-hike",
    "ph-person-simple-run",
    "ph-person-simple-ski",
    "ph-person-simple-snowboard",
    "ph-person-simple-swim",
    "ph-person-simple-tai-chi",
    "ph-person-simple-throw",
    "ph-person-simple-walk",
    "ph-perspective",
    "ph-phone",
    "ph-phone-call",
    "ph-phone-disconnect",
    "ph-phone-incoming",
    "ph-phone-list",
    "ph-phone-outgoing",
    "ph-phone-pause",
    "ph-phone-plus",
    "ph-phone-slash",
    "ph-phone-transfer",
    "ph-phone-x",
    "ph-phosphor-logo",
    "ph-pi",
    "ph-piano-keys",
    "ph-picnic-table",
    "ph-picture-in-picture",
    "ph-piggy-bank",
    "ph-pill",
    "ph-ping-pong",
    "ph-pint-glass",
    "ph-pinterest-logo",
    "ph-pinwheel",
    "ph-pipe",
    "ph-pipe-wrench",
    "ph-pix-logo",
    "ph-pizza",
    "ph-placeholder",
    "ph-planet",
    "ph-plant",
    "ph-play",
    "ph-play-circle",
    "ph-play-pause",
    "ph-playlist",
    "ph-plug",
    "ph-plug-charging",
    "ph-plugs",
    "ph-plugs-connected",
    "ph-plus",
    "ph-plus-circle",
    "ph-plus-minus",
    "ph-plus-square",
    "ph-poker-chip",
    "ph-police-car",
    "ph-polygon",
    "ph-popcorn",
    "ph-popsicle",
    "ph-potted-plant",
    "ph-power",
    "ph-prescription",
    "ph-presentation",
    "ph-presentation-chart",
    "ph-printer",
    "ph-prohibit",
    "ph-prohibit-inset",
    "ph-projector-screen",
    "ph-projector-screen-chart",
    "ph-pulse",
    "ph-activity",
    "ph-push-pin",
    "ph-push-pin-simple",
    "ph-push-pin-simple-slash",
    "ph-push-pin-slash",
    "ph-puzzle-piece",
    "ph-qr-code",
    "ph-question",
    "ph-question-mark",
    "ph-queue",
    "ph-quotes",
    "ph-rabbit",
    "ph-racquet",
    "ph-radical",
    "ph-radio",
    "ph-radio-button",
    "ph-radioactive",
    "ph-rainbow",
    "ph-rainbow-cloud",
    "ph-ranking",
    "ph-read-cv-logo",
    "ph-receipt",
    "ph-receipt-x",
    "ph-record",
    "ph-rectangle",
    "ph-rectangle-dashed",
    "ph-recycle",
    "ph-reddit-logo",
    "ph-repeat",
    "ph-repeat-once",
    "ph-replit-logo",
    "ph-resize",
    "ph-rewind",
    "ph-rewind-circle",
    "ph-road-horizon",
    "ph-robot",
    "ph-rocket",
    "ph-rocket-launch",
    "ph-rows",
    "ph-rows-plus-bottom",
    "ph-rows-plus-top",
    "ph-rss",
    "ph-rss-simple",
    "ph-rug",
    "ph-ruler",
    "ph-sailboat",
    "ph-scales",
    "ph-scan",
    "ph-scan-smiley",
    "ph-scissors",
    "ph-scooter",
    "ph-screencast",
    "ph-screwdriver",
    "ph-scribble",
    "ph-scribble-loop",
    "ph-scroll",
    "ph-seal",
    "ph-circle-wavy",
    "ph-seal-check",
    "ph-circle-wavy-check",
    "ph-seal-percent",
    "ph-seal-question",
    "ph-circle-wavy-question",
    "ph-seal-warning",
    "ph-circle-wavy-warning",
    "ph-seat",
    "ph-seatbelt",
    "ph-security-camera",
    "ph-selection",
    "ph-selection-all",
    "ph-selection-background",
    "ph-selection-foreground",
    "ph-selection-inverse",
    "ph-selection-plus",
    "ph-selection-slash",
    "ph-shapes",
    "ph-share",
    "ph-share-fat",
    "ph-share-network",
    "ph-shield",
    "ph-shield-check",
    "ph-shield-checkered",
    "ph-shield-chevron",
    "ph-shield-plus",
    "ph-shield-slash",
    "ph-shield-star",
    "ph-shield-warning",
    "ph-shipping-container",
    "ph-shirt-folded",
    "ph-shooting-star",
    "ph-shopping-bag",
    "ph-shopping-bag-open",
    "ph-shopping-cart",
    "ph-shopping-cart-simple",
    "ph-shovel",
    "ph-shower",
    "ph-shrimp",
    "ph-shuffle",
    "ph-shuffle-angular",
    "ph-shuffle-simple",
    "ph-sidebar",
    "ph-sidebar-simple",
    "ph-sigma",
    "ph-sign-in",
    "ph-sign-out",
    "ph-signature",
    "ph-signpost",
    "ph-sim-card",
    "ph-siren",
    "ph-sketch-logo",
    "ph-skip-back",
    "ph-skip-back-circle",
    "ph-skip-forward",
    "ph-skip-forward-circle",
    "ph-skull",
    "ph-skype-logo",
    "ph-slack-logo",
    "ph-sliders",
    "ph-sliders-horizontal",
    "ph-slideshow",
    "ph-smiley",
    "ph-smiley-angry",
    "ph-smiley-blank",
    "ph-smiley-meh",
    "ph-smiley-melting",
    "ph-smiley-nervous",
    "ph-smiley-sad",
    "ph-smiley-sticker",
    "ph-smiley-wink",
    "ph-smiley-x-eyes",
    "ph-snapchat-logo",
    "ph-sneaker",
    "ph-sneaker-move",
    "ph-snowflake",
    "ph-soccer-ball",
    "ph-sock",
    "ph-solar-panel",
    "ph-solar-roof",
    "ph-sort-ascending",
    "ph-sort-descending",
    "ph-soundcloud-logo",
    "ph-spade",
    "ph-sparkle",
    "ph-speaker-hifi",
    "ph-speaker-high",
    "ph-speaker-low",
    "ph-speaker-none",
    "ph-speaker-simple-high",
    "ph-speaker-simple-low",
    "ph-speaker-simple-none",
    "ph-speaker-simple-slash",
    "ph-speaker-simple-x",
    "ph-speaker-slash",
    "ph-speaker-x",
    "ph-speedometer",
    "ph-sphere",
    "ph-spinner",
    "ph-spinner-ball",
    "ph-spinner-gap",
    "ph-spiral",
    "ph-split-horizontal",
    "ph-split-vertical",
    "ph-spotify-logo",
    "ph-spray-bottle",
    "ph-square",
    "ph-square-half",
    "ph-square-half-bottom",
    "ph-square-logo",
    "ph-square-split-horizontal",
    "ph-square-split-vertical",
    "ph-squares-four",
    "ph-stack",
    "ph-stack-minus",
    "ph-stack-overflow-logo",
    "ph-stack-plus",
    "ph-stack-simple",
    "ph-stairs",
    "ph-stamp",
    "ph-standard-definition",
    "ph-star",
    "ph-star-and-crescent",
    "ph-star-four",
    "ph-star-half",
    "ph-star-of-david",
    "ph-steam-logo",
    "ph-steering-wheel",
    "ph-steps",
    "ph-stethoscope",
    "ph-sticker",
    "ph-stool",
    "ph-stop",
    "ph-stop-circle",
    "ph-storefront",
    "ph-strategy",
    "ph-stripe-logo",
    "ph-student",
    "ph-subset-of",
    "ph-subset-proper-of",
    "ph-subtitles",
    "ph-subtitles-slash",
    "ph-subtract",
    "ph-subtract-square",
    "ph-subway",
    "ph-suitcase",
    "ph-suitcase-rolling",
    "ph-suitcase-simple",
    "ph-sun",
    "ph-sun-dim",
    "ph-sun-horizon",
    "ph-sunglasses",
    "ph-superset-of",
    "ph-superset-proper-of",
    "ph-swap",
    "ph-swatches",
    "ph-swimming-pool",
    "ph-sword",
    "ph-synagogue",
    "ph-syringe",
    "ph-t-shirt",
    "ph-table",
    "ph-tabs",
    "ph-tag",
    "ph-tag-chevron",
    "ph-tag-simple",
    "ph-target",
    "ph-taxi",
    "ph-tea-bag",
    "ph-telegram-logo",
    "ph-television",
    "ph-television-simple",
    "ph-tennis-ball",
    "ph-tent",
    "ph-terminal",
    "ph-terminal-window",
    "ph-test-tube",
    "ph-text-a-underline",
    "ph-text-aa",
    "ph-text-align-center",
    "ph-text-align-justify",
    "ph-text-align-left",
    "ph-text-align-right",
    "ph-text-b",
    "ph-text-bolder",
    "ph-text-columns",
    "ph-text-h",
    "ph-text-h-five",
    "ph-text-h-four",
    "ph-text-h-one",
    "ph-text-h-six",
    "ph-text-h-three",
    "ph-text-h-two",
    "ph-text-indent",
    "ph-text-italic",
    "ph-text-outdent",
    "ph-text-strikethrough",
    "ph-text-subscript",
    "ph-text-superscript",
    "ph-text-t",
    "ph-text-t-slash",
    "ph-text-underline",
    "ph-textbox",
    "ph-thermometer",
    "ph-thermometer-cold",
    "ph-thermometer-hot",
    "ph-thermometer-simple",
    "ph-threads-logo",
    "ph-three-d",
    "ph-thumbs-down",
    "ph-thumbs-up",
    "ph-ticket",
    "ph-tidal-logo",
    "ph-tiktok-logo",
    "ph-tilde",
    "ph-timer",
    "ph-tip-jar",
    "ph-tipi",
    "ph-tire",
    "ph-toggle-left",
    "ph-toggle-right",
    "ph-toilet",
    "ph-toilet-paper",
    "ph-toolbox",
    "ph-tooth",
    "ph-tornado",
    "ph-tote",
    "ph-tote-simple",
    "ph-towel",
    "ph-tractor",
    "ph-trademark",
    "ph-trademark-registered",
    "ph-traffic-cone",
    "ph-traffic-sign",
    "ph-traffic-signal",
    "ph-train",
    "ph-train-regional",
    "ph-train-simple",
    "ph-tram",
    "ph-translate",
    "ph-trash",
    "ph-trash-simple",
    "ph-tray",
    "ph-tray-arrow-down",
    "ph-archive-tray",
    "ph-tray-arrow-up",
    "ph-treasure-chest",
    "ph-tree",
    "ph-tree-evergreen",
    "ph-tree-palm",
    "ph-tree-structure",
    "ph-tree-view",
    "ph-trend-down",
    "ph-trend-up",
    "ph-triangle",
    "ph-triangle-dashed",
    "ph-trolley",
    "ph-trolley-suitcase",
    "ph-trophy",
    "ph-truck",
    "ph-truck-trailer",
    "ph-tumblr-logo",
    "ph-twitch-logo",
    "ph-twitter-logo",
    "ph-umbrella",
    "ph-umbrella-simple",
    "ph-union",
    "ph-unite",
    "ph-unite-square",
    "ph-upload",
    "ph-upload-simple",
    "ph-usb",
    "ph-user",
    "ph-user-check",
    "ph-user-circle",
    "ph-user-circle-check",
    "ph-user-circle-dashed",
    "ph-user-circle-gear",
    "ph-user-circle-minus",
    "ph-user-circle-plus",
    "ph-user-focus",
    "ph-user-gear",
    "ph-user-list",
    "ph-user-minus",
    "ph-user-plus",
    "ph-user-rectangle",
    "ph-user-sound",
    "ph-user-square",
    "ph-user-switch",
    "ph-users",
    "ph-users-four",
    "ph-users-three",
    "ph-van",
    "ph-vault",
    "ph-vector-three",
    "ph-vector-two",
    "ph-vibrate",
    "ph-video",
    "ph-video-camera",
    "ph-video-camera-slash",
    "ph-video-conference",
    "ph-vignette",
    "ph-vinyl-record",
    "ph-virtual-reality",
    "ph-virus",
    "ph-visor",
    "ph-voicemail",
    "ph-volleyball",
    "ph-wall",
    "ph-wallet",
    "ph-warehouse",
    "ph-warning",
    "ph-warning-circle",
    "ph-warning-diamond",
    "ph-warning-octagon",
    "ph-washing-machine",
    "ph-watch",
    "ph-wave-sawtooth",
    "ph-wave-sine",
    "ph-wave-square",
    "ph-wave-triangle",
    "ph-waveform",
    "ph-waveform-slash",
    "ph-waves",
    "ph-webcam",
    "ph-webcam-slash",
    "ph-webhooks-logo",
    "ph-wechat-logo",
    "ph-whatsapp-logo",
    "ph-wheelchair",
    "ph-wheelchair-motion",
    "ph-wifi-high",
    "ph-wifi-low",
    "ph-wifi-medium",
    "ph-wifi-none",
    "ph-wifi-slash",
    "ph-wifi-x",
    "ph-wind",
    "ph-windmill",
    "ph-windows-logo",
    "ph-wine",
    "ph-wrench",
    "ph-x",
    "ph-x-circle",
    "ph-x-logo",
    "ph-x-square",
    "ph-yarn",
    "ph-yin-yang",
    "ph-youtube-logo",
  ].map((key) => [
    key,
    {
      component: () => CustomIcon,
      props: {
        name: key,
        icon: key,
      },
    },
  ])
);

export const mdiAliasesToPh = Object.fromEntries([
  ["add", "ph-plus"],
  ["collapse", "ph-caret-up"],
  ["complete", "ph-check"],
  ["cancel", "ph-x-circle"],
  ["close", "ph-x"],
  ["delete", "ph-x-circle"],
  ["clear", "ph-x"],
  ["success", "ph-check-circle"],
  ["info", "ph-info"],
  ["warning", "ph-warning-circle"],
  ["error", "ph-x-circle"],
  ["prev", "ph-caret-left"],
  ["next", "ph-caret-right"],
  ["checkboxOn", "ph-check-square"],
  ["checkboxOff", "ph-square"],
  ["checkboxIndeterminate", "ph-minus-square"],
  ["delimiter", "ph-fill ph-circle"],
  ["sortAsc", "ph-arrow-up"],
  ["sortDesc", "ph-arrow-down"],
  ["expand", "ph-caret-down"],
  ["menu", "ph-list"],
  ["subgroup", "ph-caret-down"],
  ["dropdown", "ph-caret-down"],
  ["radioOn", "ph-fill ph-radio-button"],
  ["radioOff", "ph-radio-button"],
  ["edit", "ph-pencil-simple"],
  ["ratingEmpty", "ph-star"],
  ["ratingFull", "ph-fill ph-star"],
  ["ratingHalf", "ph-fill ph-star-half"],
  ["loading", "ph-spinner"], // ou ph-arrows-counter-clockwise
  ["first", "ph-caret-line-left"],
  ["last", "ph-caret-line-right"],
  ["unfold", "ph-caret-up-down"], // ou arrows-out-line-vertical
  ["file", "ph-paperclip"],
  ["plus", "ph-plus"],
  ["minus", "ph-minus"],
  ["calendar", "ph-calendar"],
  ["treeviewCollapse", "ph-caret-down"],
  ["treeviewExpand", "ph-caret-right"],
  ["eyeDropper", "ph-eyedropper"],
]);
