import { mdiAliasesToPh, phosphorIcons } from "@/plugins/phosphorIcons.js";
import store from "@/store";
import styles from "@/styles/variables.scss";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@phosphor-icons/web";
import Vue from "vue";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";
import pt from "vuetify/es5/locale/pt";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  defaultTheme: "light",
  icons: {
    // iconfont: "mdi",
    values: {
      ...mdiAliasesToPh,
      ...phosphorIcons,
    },
  },
  lang: {
    locales: { pt, en, es },
    current: "pt",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: store.getters.primaryColor,
        accent: store.getters.secondaryColor,
        primarySuite: styles.primarySuite,
        accentSuite: styles.accentSuite,
        tertiarySuite: styles.tertiarySuite,
        primaryTrack: styles.primaryTrack,
        accentTrack: styles.accentTrack,
        primarySign: styles.primarySign,
        accentSign: styles.accentSign,
        primaryControl: styles.primaryControl,
        accentControl: styles.accentControl,
        primaryNuvem: styles.primaryNuvem,
        accentNuvem: styles.accentNuvem,
        background: "#ffffff",
        lightSuite: styles.lightSuite,
        lightSign: styles.lightSign,
        lightTrack: styles.lightTrack,
      },
      dark: {
        primary: store.getters.primaryColor,
        accent: store.getters.secondaryColor,
        primarySuite: styles.primarySuite,
        accentSuite: styles.accentSuite,
        tertiarySuite: styles.tertiarySuite,
        primaryTrack: styles.primaryTrack,
        accentTrack: styles.accentTrack,
        primarySign: styles.primarySign,
        accentSign: styles.accentSign,
        primaryControl: styles.primaryControl,
        accentControl: styles.accentControl,
        primaryNuvem: styles.primaryNuvem,
        accentNuvem: styles.accentNuvem,
        background: "#202124",
      },
    },
  },
});
