import { i18n } from "@/plugins/vue-i18n";
import { VImg } from "vuetify/lib";

export const guides = {
  tags: {
    custom: true,
    title: i18n.t("guide.steps.tags.title"),
    description: i18n.t("guide.steps.tags.description"),
    steps: [
      {
        is: "b",
        inner: i18n.t("guide.steps.tags.whatChanged"),
      },
      {
        is: "p",
        inner: i18n.t("guide.steps.tags.toBecome"),
      },
      {
        is: "b",
        inner: i18n.t("guide.steps.tags.are"),
      },
      {
        is: "p",
        inner: i18n.t("guide.steps.tags.info"),
      },
      {
        is: "b",
        inner: i18n.t("guide.steps.tags.whyChange"),
      },
      {
        is: "p",
        inner: i18n.t("guide.steps.tags.term"),
      },
      {
        is: "b",
        inner: i18n.t("guide.steps.tags.affects"),
      },
      {
        is: "p",
        inner: i18n.t("guide.steps.tags.same"),
      },
      {
        is: "p",
        inner: i18n.t("guide.steps.tags.support"),
      },
    ],
  },
  signApply: {
    custom: true,
    title: i18n.t("guide.steps.signApply.title"),
    description: i18n.t("guide.steps.signApply.description"),
    steps: [
      {
        is: "ol",
        elements: [
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.menu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.navigate"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/how_to_access_users.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.useSelection"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_selection.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.openApplyMenu"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_menu.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.type"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_type.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.model"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_model.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.preview"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_preview.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.next"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_next.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.method"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_method.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.signApply.apply"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/sign_apply_apply.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
        ],
      },
    ],
  },
  insertTags: {
    custom: true,
    title: i18n.t("guide.steps.insertTags.title"),
    description: i18n.t("guide.steps.insertTags.description"),
    steps: [
      {
        is: "ol",
        elements: [
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.menu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.navigate"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/how_to_access_models.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.modelType"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.editMenu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.duplicateEdit"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/insert_tags_duplicate_edit.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.addInfo"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/insert_tags_add_info.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.insertTags.select"),
          },
        ],
      },
    ],
  },
  batchEditUserTags: {
    custom: true,
    title: i18n.t("guide.steps.batchEditUserTags.title"),
    description: i18n.t("guide.steps.batchEditUserTags.description"),
    steps: [
      {
        is: "ol",
        elements: [
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.menu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.navigate"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/how_to_access_users.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.dotDotDot"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.csv"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/batch_edit_user_tags_csv.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.download"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/batch_edit_user_tags_download.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.edit"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.upload"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/batch_edit_user_tags_upload.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.batchEditUserTags.save"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/batch_edit_user_tags_save.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
        ],
      },
    ],
  },
  editUserTags: {
    custom: true,
    title: i18n.t("guide.steps.editUserTags.title"),
    description: i18n.t("guide.steps.editUserTags.description"),
    steps: [
      {
        is: "ol",
        elements: [
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.menu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.navigate"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/how_to_access_users.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.user"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/edit_user_tags_user.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.openEditMenu"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/edit_user_tags_open.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.edit"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.save"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/edit_user_tags_save.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editUserTags.repeat"),
          },
        ],
      },
    ],
  },
  editCompanyTags: {
    custom: true,
    title: i18n.t("guide.steps.editCompanyTags.title"),
    description: i18n.t("guide.steps.editCompanyTags.description"),
    steps: [
      {
        is: "ol",
        elements: [
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.menu"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.navigate"),
          },
          {
            is: VImg,
            bind: {
              src: require("@/assets/images/guides/how_to_access_profile.png"),
              maxHeight: "261px",
              maxWidth: "270px",
            },
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.profile"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.select"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.edit"),
          },
          {
            is: "li",
            inner: i18n.t("guide.steps.editCompanyTags.save"),
          },
        ],
      },
    ],
  },
};
