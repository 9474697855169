import { modules } from "@/helpers/variables/modules";
import { findUserById } from "@/helpers/services/getUsers";

export const suiteRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [],
    },
  },
  {
    path: "/onboarding-survey",
    name: "OnboardingSurvey",
    component: () => import("@/views/OnboardingSurvey.vue"),
    meta: {
      app: modules.suite,
      requiresAuth: true,
      permission: [],
    },
  },
  {
    path: "/install",
    name: "Install",
    component: () => import("@/views/Install.vue"),
    meta: {
      app: modules.suite,
      requiresAuth: true,
      permission: [],
    },
  },
  {
    path: "/workspace-checklist",
    name: "Workspace Checklist",
    component: () => import("@/views/admin/WorkspaceChecklist.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [],
    },
  },
  {
    path: "/onboarding-new-user",
    name: "Onboarding",
    component: () => import("@/views/admin/OnboardingNewUser.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite],
    },
  },
  {
    path: "/offboarding-user",
    name: "Offboarding",
    component: () => import("@/views/admin/OffboardingUser.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite],
    },
  },
  {
    path: "/contact",
    name: "ContactUs",
    component: () => import("@/views/help/ContactUs.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Fale conosco",
        },
      ],
    },
  },
  {
    path: "/admin",
    redirect: "/settings/user-permissions",
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/users/Users.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Usuários",
        },
      ],
    },
  },
  {
    path: "/admin",
    redirect: "/settings/user-permissions",
  },
  {
    path: "/settings/user-permissions",
    name: "AdminFunctions",
    component: () => import("@/views/admin/AdminFunctions.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "manage_admins"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Permissões de Usuários",
        },
      ],
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import("@/views/admin/Reports.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reports"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Relatórios",
        },
      ],
    },
  },
  {
    path: "/reports/signatures",
    name: "ReportsSignatures",
    component: () => import("@/views/admin/ReportsSignatures.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reports"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Relatórios de assinaturas de e-mail",
        },
      ],
    },
  },
  {
    path: "/reports/accounts",
    name: "AccountsReports",
    component: () => import("@/views/admin/AccountsReports.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reports"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Relatórios de contas de usuários",
        },
      ],
    },
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: () => import("@/views/admin/Inventory.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "inventory"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Inventário digital",
        },
      ],
    },
  },
  {
    path: "/drive-migration",
    name: "DriveMigration",
    component: () => import("@/views/admin/DriveMigration.vue"),
    meta: {
      requiresAuth: true,
      conectaOnly: true,
      app: modules.suite,
      permission: [modules.suite, "drive-migration", "conecta"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Transferência de arquivos",
        },
      ],
    },
  },
  {
    path: "/email-migration",
    name: "EmailMigration",
    component: () => import("@/views/admin/EmailMigration.vue"),
    meta: {
      requiresAuth: true,
      conectaOnly: true,
      app: modules.suite,
      permission: [modules.suite, "email-migration", "conecta"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Migração de e-mails",
        },
      ],
    },
  },
  {
    path: "/company",
    redirect: "/settings/profile",
  },
  {
    path: "/settings/profile",
    name: "Company",
    component: () => import("@/views/company/Profile.vue"),
    props: (route) => ({ startingMenu: route.query.startingMenu }),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "company_profile"],
    },
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/users/User.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite],
      breadCrumb(route) {
        const id = route.query.id;
        let user = findUserById(id);
        return [
          {
            text: "Início",
            to: { name: "Home" },
          },
          {
            text: "Usuários",
            to: { name: "Users" },
          },
          {
            text: user.name,
          },
        ];
      },
    },
  },
  {
    path: "/billing/plans",
    name: "BillingPlans",
    component: () => import("@/views/billing/Plans.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "billing"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Meus planos e serviços",
        },
      ],
    },
  },
  {
    path: "/billing/add-plan/:plan_sku?",
    redirect: "/billing/upgrade/:plan_sku?",
    name: "BillingAddPlan",
  },
  {
    path: "/billing/upgrade/:plan_sku?",
    name: "BillingUpgradePlan",
    component: () => import("@/views/billing/UpgradePlan.vue"),
    meta: {
      requiresAuth: true,
      conectaOnly: true,
      app: modules.suite,
      permission: [modules.suite, "billing"],
    },
  },
  {
    path: "/billing/transactions",
    name: "BillingTransactions",
    component: () => import("@/views/billing/Transactions.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "billing"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Meus planos e serviços",
          to: { name: "BillingPlans" },
        },
        {
          text: "Histórico de Cobrança",
        },
      ],
    },
  },
  {
    path: "/plan-management",
    redirect: "/admin/plans/edit",
  },
  {
    path: "/admin/plans/edit",
    name: "PlansManager",
    component: () => import("@/views/super_admin/Plans.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      admin: true,
      permission: [modules.suite, "billing", "conecta"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Gerenciar painel",
        },
        {
          text: "Gerenciar planos",
        },
      ],
    },
  },
  {
    path: "/admin/services/edit",
    name: "ServicesManager",
    component: () => import("@/views/super_admin/Services.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      admin: true,
      permission: [modules.suite, "billing", "conecta"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Gerenciar painel",
        },
        {
          text: "Gerenciar serviços",
        },
      ],
    },
  },
  {
    path: "/admin/ban",
    name: "BanClients",
    component: () => import("@/views/super_admin/BanClients.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      admin: true,
      permission: [modules.suite, "conecta"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Gerenciar painel",
        },
        {
          text: "Clientes banidos",
        },
      ],
    },
  },
  {
    path: "/reseller/clients",
    redirect: "/partner/customers",
  },
  {
    path: "/partner/customers",
    name: "ResellerCustomers",
    component: () => import("@/views/reseller/Customers.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reseller"],
    },
  },
  {
    path: "/reseller/client",
    redirect: "/partner/customer",
  },
  {
    path: "/partner/customer",
    name: "ResellerClientInfo",
    component: () => import("@/views/reseller/Customer.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reseller"],
    },
  },
  {
    path: "/reseller/new-plan",
    redirect: "/partner/customer/plans/new",
  },
  {
    path: "/partner/customer/plans/new",
    name: "ResellerNewPlan",
    component: () => import("@/views/reseller/NewPlan.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reseller"],
    },
  },
  {
    path: "/reseller/new-service",
    redirect: "/partner/customer/services/new",
  },
  {
    path: "/partner/customer/services/new",
    name: "ResellerNewService",
    component: () => import("@/views/reseller/NewService.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reseller"],
    },
  },
  {
    path: "/reseller/billing",
    redirect: "/partner/billing",
  },
  {
    path: "/partner/billing",
    name: "ResellerBilling",
    component: () => import("@/views/reseller/Transactions.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      permission: [modules.suite, "reseller"],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Faturamento",
        },
      ],
    },
  },
  {
    path: "/admin/notifications",
    name: "CentralNotifications",
    component: () => import("@/views/reseller/NotificationDashboard.vue"),
    meta: {
      requiresAuth: true,
      app: modules.suite,
      admin: true,
      permission: [modules.suite],
      breadCrumb: [
        {
          text: "Início",
          to: { name: "Home" },
        },
        {
          text: "Gerenciar painel",
        },
        {
          text: "Central de notificações",
        },
      ],
    },
  },
];
